import * as Yup from 'yup';
import {
  isNameValid, isPhoneNumberValid, isEmailValid, isAmountValid
} from 'shared/utils/validations';

export const getRatingDescription = (hoverScore: number) => {
  switch (hoverScore) {
    case 1:
      return 'Terrible';
    case 2:
      return 'Poor';
    case 3:
      return 'Average';
    case 4:
      return 'Very Good';
    case 5:
      return 'Excellent';
    default:
      return 'Select your rating';
  }
};

const getMainFields = (isContentValid: (value: string) => Promise<boolean>) => ({
  score: Yup.number().required(),
  notes: Yup.string().test({
    name: 'minWords',
    test: (value) => !value || value.split(/ |\n/).filter(Boolean).length >= 10,
    message: 'Please enter your review (10 words or more)'
  }).test({
    name: 'validContent',
    test: async (value) => {
      const isValid = await isContentValid(value ?? '');
      return !value || isValid;
    },
    message: 'Your description does not align with our review guidelines, please revise'
  }).required('Please enter your review'),
  pricePaid: Yup.string().notRequired().test('pricePaid', 'Please enter a valid job cost', (value) => {
    if (value) {
      const schema = Yup.string().min(1).test({
        test: (value) => isAmountValid(value)
      });
      return schema.isValidSync(value);
    }
    return true;
  })
});

export const getCreateFeedbackSchema = (
  isLogin: boolean,
  isContentValid: (value: string) => Promise<boolean>
) => (isLogin
  ? Yup.object().shape({
    ...getMainFields(isContentValid)
  })
  : Yup.object().shape({
    ...getMainFields(isContentValid),
    firstName: Yup.string().test({
      name: 'validFirstName',
      test: (value) => !value || isNameValid(value),
      message: 'Please enter valid first name'
    }).required('Please enter your first name'),
    lastName: Yup.string().test({
      name: 'validLastName',
      test: (value) => !value || isNameValid(value),
      message: 'Please enter valid last name'
    }).required('Please enter your last name'),
    email: Yup.string().test({
      name: 'validEmail',
      test: (value) => !value || isEmailValid(value),
      message: 'Please enter a valid email address'
    }).required('Please enter your email address'),
    phone: Yup.string().test({
      name: 'validMobile',
      test: (value) => !value || isPhoneNumberValid(value, 'MOBILE'),
      message: 'Please enter a valid mobile number'
    }).required('Please enter your mobile number')
  })
);
