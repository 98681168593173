export { default as Profile } from './Profile';
export { default as RatingSummary } from './RatingSummary';
export { default as ContactDetails } from './ContactDetails';
export { default as SocialMedia } from './SocialMedia';
export { default as OpeningHours } from './OpeningHours';
export { default as Description } from './Description';
export { default as BadgesAwards } from './BadgesAwards';
export { default as AcceptedPayments } from './AcceptedPayments';
export { default as NearbyLocations } from './NearbyLocations';
export { default as Services } from './Services';
