import { FC, memo } from 'react';
import {
  AdditionInfoStyled,
  SuccessStyled,
  AdditionLabelStyled
} from './styled';

type Props = {
  openPublicHolidays: boolean;
};

const AdditionalInfo: FC<Props> = ({ openPublicHolidays }: Props) => {
  if (!openPublicHolidays) return null;

  return (
    <AdditionInfoStyled>
      <SuccessStyled />
      <AdditionLabelStyled>
        Open public holidays
      </AdditionLabelStyled>
    </AdditionInfoStyled>
  );
};

export default memo(AdditionalInfo);
