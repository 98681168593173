import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import StarRating from '@oneflare/flarekit/lib/components/StarRating';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Button from '@oneflare/flarekit/lib/components/Button';
import InputCounter from '@oneflare/flarekit/lib/components/InputCounter';
import Textfield from '@oneflare/flarekit/lib/components/Textfield';

export const RatingStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;

  ${media.mdUp`
    flex-direction: row;
  `}
`;

export const StarRatingStyled = styled(StarRating)`
  ${media.mdUp`
    margin-right: 24px;
  `}
`;

export const RatingDescriptionStyled = styled(Paragraph)`
  margin: 16px 0;
`;

export const CollapsibleContentStyled = styled.div`
  margin-top: 32px;
`;

export const UserParamsStyled = styled.div`
  display: grid;
  row-gap: 24px;
  column-gap: 16px;
  margin: 24px 0;

  ${media.mdUp`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const ParagraphStyled = styled(Paragraph)`
  margin-bottom: 16px;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;

  ${media.mdUp`
    width: auto;
  `}
`;

export const InputCounterStyled = styled(InputCounter)`
  margin-bottom: 24px;
`;

export const TextfieldStyled = styled(Textfield)`
  margin-bottom: 20px;
`;
