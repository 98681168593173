export const FILE_STATUS_OBJECT = {
  none: {
    tag: 'Pending',
    kind: 'unsuccessful'
  },
  approved: {
    tag: 'Approved',
    kind: 'successDarkFilled'
  },
  rejected: {
    tag: 'Rejected',
    kind: 'danger'
  },
  archived: {
    tag: 'Archived',
    kind: 'aluminium'
  },
  expired: {
    tag: 'Expired',
    kind: 'danger'
  }
};
