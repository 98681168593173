import { useMutation } from '@apollo/client';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import CollapsibleContainer from '@oneflare/flarekit/lib/components/CollapsibleContainer';
import TextArea from '@oneflare/flarekit/lib/components/TextArea';
import Textfield from '@oneflare/flarekit/lib/components/Textfield';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import {
  FC,
  memo,
  useState,
  useMemo
} from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { CREATE_FEEDBACK } from 'mutations/shared/job';
import UploadDocs from 'shared/components/UploadDocs';
import { REVIEW_MEET_GUIDELINES_HELP_URL } from 'shared/constants/zendDeskHelpUrls';
import { useHandleMutationAlert, useBreakpoint } from 'shared/utils/hooks';
import { useIsContentValid } from 'shared/utils/validations';

import { SectionStyled, TitleStyled } from '../../styled/BppBody';

import {
  RatingStyled,
  StarRatingStyled,
  RatingDescriptionStyled,
  CollapsibleContentStyled,
  UserParamsStyled,
  ParagraphStyled,
  ButtonStyled,
  TextfieldStyled,
  InputCounterStyled
} from './styled/AddReview';
import { getRatingDescription, getCreateFeedbackSchema } from './utils';

type Props = {
  businessId: number;
  businessName: string;
  isLogin: boolean;
};

const AddReview: FC<Props> = ({ businessId, businessName, isLogin }: Props) => {
  const [hoverScore, setHoverScore] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [handleMutation, { loading }] = useMutation(CREATE_FEEDBACK);
  const [handleAlert] = useHandleMutationAlert();
  const isContentValid = useIsContentValid('Business Public Profile');
  const isMdUp = useBreakpoint('md');

  const parsePricePaid = (value: string) => {
    // remove $ if exist
    return value ? Number(value.replace(/[^\d.-]+/g, '')).toFixed(2) : null;
  };
  const handleSubmitFeedback = async ({
    score,
    notes,
    pricePaid,
    firstName,
    lastName,
    email,
    phone
  }) => {
    const { data, errors } = await handleMutation({
      variables: {
        businessId,
        feedbackParams: {
          attachmentTempUrl: invoice[0]?.thumb,
          score,
          notes,
          pricePaid: parsePricePaid(pricePaid)
        },
        userParams: {
          name: `${firstName} ${lastName}`,
          email,
          phone
        }
      }
    });

    handleAlert({
      errors,
      datadogRumMsg: 'Oneflare | Business Public Profile | CREATE_FEEDBACK mutation',
      successMsg: 'Thanks for submitting a review, it\'s with our support team to verify'
    });

    return data;
  };

  const {
    values,
    touched,
    errors,
    isValid,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm
  } = useFormik({
    initialValues: {
      score: 0,
      notes: '',
      pricePaid: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    validationSchema: getCreateFeedbackSchema(isLogin, isContentValid),
    onSubmit: async (values) => {
      try {
        await handleSubmitFeedback(values);
      } catch (err) {
        DataDogRumAgent.addRumError(err, 'Oneflare | Business Public Profile | Submit feedback');
      }
      setIsSubmitted(true);
      setIsExpanded(false);
      resetForm();
      setHoverScore(0);
      setInvoice([]);
    }
  });

  const handleHoverScore = (e: React.MouseEvent<HTMLElement, MouseEvent>, hoverScore: number) => {
    setHoverScore(hoverScore);
  };

  const handleSelectScore = (e: React.MouseEvent<HTMLElement, MouseEvent>, score: number) => {
    e.preventDefault();
    setFieldValue('score', score);
    setFieldTouched('score', true);
    setIsExpanded(true);
    setIsSubmitted(false);
  };

  const wordCount = useMemo(() => (
    values.notes.split(/ |\n/).filter(Boolean).length
  ), [values.notes]);

  if (isLogin && isSubmitted) return null;

  return (
    <SectionStyled id="emailReviewAnchor">
      <span id="mobileReviewAnchor" />
      <TitleStyled $centerTitle>
        {isLogin ? `Rate your experience with ${businessName}` : 'Add your review'}
      </TitleStyled>
      <RatingStyled>
        <StarRatingStyled
          handleHover={handleHoverScore}
          handleSelect={handleSelectScore}
          isEditable={!loading && !isSubmitting}
          rating={values.score}
          starStyles={{
            xs: {
              size: '46px',
              spacing: '4px'
            }
          }}
        />
        <RatingDescriptionStyled>{getRatingDescription(hoverScore)}</RatingDescriptionStyled>
      </RatingStyled>
      <CollapsibleContainer expanded={isExpanded}>
        <CollapsibleContentStyled>
          {/* An invoice or receipt is required when rating is 1 or 2 stars */}
          {[1, 2].includes(values.score) && (
            <UploadDocs
              copy="To verify your review, please upload the invoice or receipt for the job."
              disabled={loading || isSubmitting}
              hasSingleFileLimit
              kind="invoice"
              setDocsList={setInvoice}
              isSubmitted={isSubmitted}
            />
          )}
          <TextArea
            id="notes"
            name="notes"
            placeholder="Share your experience with other Oneflare customers"
            initialHeight={100}
            value={values.notes}
            error={touched.notes && errors.notes}
            disabled={loading || isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputCounterStyled
            current={wordCount}
            limit={10}
          />
          <TextfieldStyled
            id="pricePaid"
            name="pricePaid"
            label="Total job cost (optional)"
            placeholder="$225"
            value={values.pricePaid}
            error={touched.pricePaid && errors.pricePaid}
            disabled={loading || isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!isLogin && (
            <UserParamsStyled>
              <Textfield
                id="firstName"
                name="firstName"
                label="First name"
                placeholder="First name"
                value={values.firstName}
                error={touched.firstName && errors.firstName}
                disabled={loading || isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Textfield
                id="lastName"
                name="lastName"
                label="Last name"
                placeholder="Last name"
                value={values.lastName}
                error={touched.lastName && errors.lastName}
                disabled={loading || isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Textfield
                id="email"
                name="email"
                label="Email"
                type="email"
                placeholder="Email address"
                value={values.email}
                error={touched.email && errors.email}
                disabled={loading || isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Textfield
                id="phone"
                name="phone"
                label="Mobile"
                type="tel"
                placeholder="Mobile number"
                maxLength={10}
                value={values.phone}
                error={touched.phone && errors.phone}
                disabled={loading || isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </UserParamsStyled>
          )}
          <ParagraphStyled>
            {'By selecting “Publish” you are agreeing to our '}
            <Anchor
              href={REVIEW_MEET_GUIDELINES_HELP_URL}
              rel="noopener"
              target="_blank"
            >
              review guidelines
            </Anchor>
            .
          </ParagraphStyled>
          <ButtonStyled
            type="submit"
            kind={isExpanded && isMdUp ? 'primary-sm' : 'primary'}
            label="Publish"
            onClick={handleSubmit as unknown as React.MouseEventHandler<HTMLButtonElement>}
            isLoading={loading || isSubmitting}
            disabled={!isValid || (values.score < 3 && isEmpty(invoice))}
          />
        </CollapsibleContentStyled>
      </CollapsibleContainer>
    </SectionStyled>
  );
};

export default memo(AddReview);
