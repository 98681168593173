import { styled } from 'styled-components';

export const StarRatingStyled = styled.div`
  display: flex;
  font-size: 0;

  .star {
    height: 25px;
    width: 26px;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  .primaryFill {
    fill: ${({ theme, $fillBackground }) => $fillBackground || theme.color.aluminium};
  }

  .secondaryFill {
    fill: ${({ theme, $fillStar }) => $fillStar || theme.color.tertiaryDark};
  }
`;
