import {
  memo, useState, useEffect, useCallback
} from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { UPDATE_BUSINESS_CREDENTIALS, REMOVE_BUSINESS_CREDENTIALS } from 'mutations/shared/businessProfile';
import { GET_CURRENT_BUSINESS_CREDENTIALS } from 'queries/shared/getCurrentBusinessProfile';
import ErrorFileList from 'shared/components/ErrorFileList';
import DocItem from 'shared/components/DocItem';
import ConfirmCancelModal from 'shared/components/ConfirmCancelModal';
import {
  UploadDocsWrapperStyled,
  TitleStyled,
  DescriptionWrapperStyled,
  TipsStyled,
  UploaderWrapperStyled,
  UploadFilesStyled
} from './styled/UploadDocs';

const propTypes = {
  copy: PropTypes.string.isRequired, // Description of the uploader
  disabled: PropTypes.bool,
  docs: PropTypes.arrayOf(PropTypes.shape), // Existing documents passed in from parent component
  hasSingleFileLimit: PropTypes.bool, // Whether only one document can be uploaded
  isSubmitted: PropTypes.bool, // Whether form in parent component has been submitted
  kind: PropTypes.oneOf(['invoice', 'credentials']),
  setDocsList: PropTypes.func, // Parent component to get the uploaded document(s)
  title: PropTypes.string, // Uploader title
  type: PropTypes.string // Credential type
};

const defaultProps = {
  disabled: false,
  docs: [],
  hasSingleFileLimit: false,
  isSubmitted: false,
  kind: 'credentials',
  setDocsList: () => {},
  title: '',
  type: ''
};

const UploadDocs = ({
  title,
  copy,
  disabled,
  docs,
  type,
  hasSingleFileLimit,
  isSubmitted,
  kind,
  setDocsList
}) => {
  const [filesList, setFilesList] = useState([]);
  const [errorFileList, setErrorFileList] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    setFilesList(() => [...docs]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocsList(filesList);
  }, [setDocsList, filesList]);

  useEffect(() => {
    if (isSubmitted) setFilesList([]);
  }, [isSubmitted]);

  const onError = (error) => DataDogRumAgent.addRumError(error, 'Oneflare | Edit Business | Credentials');

  const [uploadCredential] = useMutation(UPDATE_BUSINESS_CREDENTIALS, {
    onError,
    awaitRefetchQueries: true
  });

  const [removeCredential, { loading }] = useMutation(REMOVE_BUSINESS_CREDENTIALS, {
    onError,
    refetchQueries: [{ query: GET_CURRENT_BUSINESS_CREDENTIALS }]
  });

  const handleUploadCredential = async ({ file: { name }, thumb }) => {
    const { data } = await uploadCredential({
      variables: {
        params: {
          documentType: type,
          documentOriginalFileName: name,
          documentTempUrl: thumb
        }
      }
    });

    return data?.createBusinessCredential?.id;
  };

  const handleErrorFilesList = useCallback((newErrorFile) => {
    setErrorFileList([...newErrorFile, ...errorFileList]);
  }, [errorFileList, setErrorFileList]);

  const handleRemoveClick = useCallback((id) => {
    setCurrentId(id);
    setIsModalShow(true);
  }, [setIsModalShow, setCurrentId]);

  const handleCancel = useCallback(() => {
    setIsModalShow(false);
  }, [setIsModalShow]);

  const handleRemoveInvoice = () => {
    setFilesList([]);
    setIsModalShow(false);
  };

  const handleRemoveCredential = async () => {
    await removeCredential({
      variables: {
        verificationDocumentId: currentId
      }
    });
    const newList = filesList.filter((credential) => credential.id !== currentId);
    setFilesList(newList);
    setIsModalShow(false);
    return currentId;
  };

  return (
    <UploadDocsWrapperStyled kind={kind}>
      <DescriptionWrapperStyled kind={kind}>
        {title && <TitleStyled>{title}</TitleStyled>}
        <TipsStyled kind={kind}>
          {copy}
        </TipsStyled>
      </DescriptionWrapperStyled>
      <UploaderWrapperStyled>
        <UploadFilesStyled
          filesList={filesList}
          setFilesList={setFilesList}
          pageTitle={kind === 'invoice' ? 'Business Public Profile' : 'Credentials'}
          pageType={kind === 'invoice' ? 'invoice' : 'files'}
          setErrorFileList={handleErrorFilesList}
          mutation={kind === 'invoice' ? undefined : handleUploadCredential}
          hasSingleFileLimit={hasSingleFileLimit}
          length={filesList.length}
        />
        <ErrorFileList
          errorFileList={errorFileList}
          setErrorFileList={setErrorFileList}
        />
        {filesList.length ? filesList.map(({
          percentage, id, documentOriginalFileName, currentState
        }) => (
          <DocItem
            key={`${documentOriginalFileName}${id}`}
            id={id}
            name={documentOriginalFileName}
            percentage={percentage}
            currentState={currentState}
            disabled={disabled}
            docKind={kind}
            handleRemoveClick={() => handleRemoveClick(id)}
            hasSingleFileLimit={hasSingleFileLimit}
          />
        )) : null}
      </UploaderWrapperStyled>
      <ConfirmCancelModal
        isModalShow={isModalShow}
        title="Remove file?"
        message="Are you sure you want to remove this file?"
        cancelLabel="Cancel"
        confirmLabel="Confirm"
        isLoading={loading}
        handleCancel={handleCancel}
        handleConfirm={kind === 'invoice' ? handleRemoveInvoice : handleRemoveCredential}
      />
    </UploadDocsWrapperStyled>
  );
};

UploadDocs.propTypes = propTypes;
UploadDocs.defaultProps = defaultProps;

export default memo(UploadDocs);
