import { ReactElement, MutableRefObject } from 'react';
import scrollToComponent from 'react-scroll-to-component-ssr';
import { SocialMediaLinkModel } from 'types/oneflare.com.au/businessPublicProfile';
import {
  InstagramStyled,
  FacebookStyled,
  LinkedInStyled,
  TwitterStyled,
  PinterestStyled,
  YouTubeStyled
} from '../components/SocialMedia/styled/SocialMedia';
import {
  AmericanExpressStyled,
  PayPalStyled,
  VisaStyled,
  MasterCardStyled,
  CircleMoneyStyled,
  DirectDepositStyled,
  ChequeStyled
} from '../components/AcceptedPayments/styled/AcceptedPayments';

export const buildSocialMediaUrl = ({ platform, url }: SocialMediaLinkModel) => {
  const baseUrl = `https://www.${platform}.com/`;
  switch (platform) {
    case 'linkedin':
      return `${baseUrl}company/${url}`;
    case 'youtube':
      return `${baseUrl}channel/${url}`;
    default:
      return `${baseUrl}${url}`;
  }
};

export const renderSocialMediaIcon = (platform: string): ReactElement => {
  switch (platform) {
    case 'instagram':
      return <InstagramStyled />;
    case 'facebook':
      return <FacebookStyled />;
    case 'linkedin':
      return <LinkedInStyled />;
    case 'twitter':
      return <TwitterStyled />;
    case 'pinterest':
      return <PinterestStyled />;
    case 'youtube':
      return <YouTubeStyled />;
    default:
      return null;
  }
};

export const renderPaymentMethodsIcon = (method: string): ReactElement => {
  switch (method) {
    case 'amex':
      return <AmericanExpressStyled />;
    case 'cash':
      return <CircleMoneyStyled />;
    case 'cheque':
      return <ChequeStyled />;
    case 'directDeposit':
      return <DirectDepositStyled />;
    case 'mastercard':
      return <MasterCardStyled />;
    case 'paypal':
      return <PayPalStyled />;
    case 'visa':
      return <VisaStyled />;
    default:
      return null;
  }
};

export const renderPaymentMethodsLabel = (method: string): string => {
  switch (method) {
    case 'amex':
      return 'American Express';
    case 'cash':
      return 'Cash';
    case 'cheque':
      return 'Cheque';
    case 'directDeposit':
      return 'Direct deposit';
    case 'mastercard':
      return 'Mastercard';
    case 'paypal':
      return 'PayPal';
    case 'visa':
      return 'Visa';
    default:
      return null;
  }
};

export const renderFullStateName = (state: string) => {
  switch (state) {
    case 'ACT':
      return 'Australian Capital Territory';
    case 'NSW':
      return 'New South Wales';
    case 'NT':
      return 'Northern Territory';
    case 'QLD':
      return 'Queensland';
    case 'SA':
      return 'South Australia';
    case 'TAS':
      return 'Tasmania';
    case 'VIC':
      return 'Victoria';
    case 'WA':
      return 'Western Australia';
    default:
      return null;
  }
};

export const scrollToReviews = (
  e: MouseEvent,
  reviewsSectionRef: MutableRefObject<HTMLElement | null>,
  isStickyHeader: boolean
) => {
  e.preventDefault();
  scrollToComponent(
    reviewsSectionRef.current,
    {
      offset: isStickyHeader ? -72 : 0,
      align: 'top',
      duration: 200,
      ease: 'linear'
    }
  );
};
