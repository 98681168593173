import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_NAME = gql`
  mutation updateBusiness($name: String!){
    updateBusiness(profileDetailBusiness: {
        name: $name
      }
    ){
      id
      name
      cachedSlug
    }
  }
`;

export const UPDATE_BUSINESS_ABN = gql`
  mutation updateBusiness($abn: String!){
    updateBusiness(profileDetailBusiness: {
        abn: $abn
      }
    ){
      id
      abn
      cachedSlug
    }
  }
`;

export const UPDATE_BUSINESS_DESCRIPTION = gql`
  mutation updateBusiness($description: String!){
    updateBusiness(profileDetailBusiness: {
      description: $description
      }
    ){
      id
      description
      cachedSlug
    }
  }
`;

export const UPDATE_BUSINESS_CONTACT = gql`
  mutation updateBusiness(
      $profileDetailUser: BusinessProfileDetailUserAttributes!,
      $profileDetailBusiness: BusinessProfileDetailBusinessAttributes!,
      $profileDetailSetting: BusinessProfileDetailSettingAttributes!
    ){
      updateBusiness(
        profileDetailUser: $profileDetailUser,
        profileDetailBusiness: $profileDetailBusiness,
        profileDetailSetting: $profileDetailSetting
      ){
        user {
          id
          name
          email
          phone
        }
        id
        address
        locationId
        canonicalAddress {
          canonicalAddressId
          canonicalAddress
          postcode
          stateTerritory
          localityName
          fullAddress
          longitude
          latitude
        }
        landline
        website
        yearFounded
        hideMobileFromPublic
        hideEmailFromPublic
        hideLandlineFromPublic
        showSuburbOnly
      }
    }
`;

export const UPDATE_PRIMARY_CATEGORY = gql`
  mutation updateBusiness(
      $profileDetailBusiness: BusinessProfileDetailBusinessAttributes!
    ){
      updateBusiness(
        profileDetailBusiness: $profileDetailBusiness
      ){
        id
        category {
          id
          name
        }
      }
    }
`;

export const UPDATE_SECONDARY_CATEGORIES = gql`
  mutation updateBusinessProfessions($categoryIds: [Int!]){
    updateBusinessProfessions(categoryIds: $categoryIds) {
      id
      name
    }
  }
`;

export const UPDATE_BUSINESS_OPENING_HOURS = gql`
  mutation createBusinessOpeningHour(
    $openingHourSettings: OpeningHourSettingAttributes!,
    $openingHours: OpeningHourAttributes!
  ){
    createBusinessOpeningHour(
      openingHourSettings: $openingHourSettings,
      openingHours: $openingHours
    ){
      openingHours {
        day
        closed
        openHours
        formattedOpenHours
      }
      openPublicHolidays
      available247
    }
  }
`;

export const UPDATE_BUSINESS_SOCIAL_MEDIA_LINKS = gql`
  mutation updateBusinessSocialMediaLinks(
    $socialMediaLinks: [SocialMediaLinkAttributes!]!
  ){
    updateBusinessSocialMediaLinks(
      socialMediaLinks: $socialMediaLinks
    ){
      id
      platform
      url
    }
  }
`;

export const UPDATE_BUSINESS_PAYMENT_METHODS = gql`
  mutation updateBusinessAcceptedPaymentMethods(
    $acceptedPaymentMethods: AcceptedPaymentMethods!
  ){
    updateBusinessAcceptedPaymentMethods(
      acceptedPaymentMethods: $acceptedPaymentMethods
    ){
      directDeposit
      cash
      cheque
      visa
      mastercard
      amex
      paypal
    }
  }
`;

export const UPDATE_BUSINESS_UNIQUE_SELLING_POINTS = gql`
  mutation createBusinessUniqueSellingPoints(
    $uniqueSellingPoints: [String!]!
  ){
    createBusinessUniqueSellingPoints(
      uniqueSellingPoints: $uniqueSellingPoints
    )
  }
`;

export const UPDATE_BUSINESS_FAQ = gql`
  mutation  createBusinessFaqAnswer( $questionId: Int!, $answer: String! ){
    createBusinessFaqAnswer(
        questionId: $questionId,
        answer: $answer
    ){
      profileQuestion {
        id
        question
      }
      id
      answer
    }
  }
`;

export const DELETE_BUSINESS_FAQ = gql`
  mutation  deleteBusinessFaqAnswer( $id: Int! ){
    deleteBusinessFaqAnswer(
       id: $id
    ){
      deletedId
    }
  }
`;

export const UPDATE_BUSINESS_CREDENTIALS = gql`
  mutation createBusinessCredential($params: CreateBusinessCredentialParams!) {
    createBusinessCredential(params: $params) {
      id
      type
      name
      documentHolderName
      documentNumber
      currentState
      expiresAt
      documentFileName
      documentContentType
      documentFileSize
      documentTempUrl
      documentOriginalFileName
    }
  }
`;

export const UPDATE_BUSINESS_IMAGES = gql`
  mutation  updatePhoto($params: UpdateParams!){
    updatePhoto(params: $params) {
      id
      name
      filename
      thumb
      original
      position
    }
  }
`;

export const REMOVE_BUSINESS_CREDENTIALS = gql`
  mutation deleteBusinessCredential($verificationDocumentId: Int!) {
    deleteBusinessCredential(verificationDocumentId: $verificationDocumentId) {
      deletedId
      error
    }
  }
`;

export const DELETE_BUSINESS_IMAGE = gql`
  mutation deletePhoto($photoId: Int!) {
    deletePhoto(photoId: $photoId) {
      deletedId
    }
  }
`;

export const UPDATE_BUSINESS_IMAGE_CAPTION = gql`
  mutation updatePhoto($params:UpdateParams!) {
    updatePhoto( params: $params ) {
      id
      name
    }
  }
`;

export const UPDATE_BUSINESS_AVATAR = gql`
  mutation updateAvatar($params: UpdateAvatarParams!) {
  updateAvatar(params: $params){
    id
    url
    fileName
  }
}
`;

export const REORDER_IMAGE = gql`
  mutation reorderPhoto($params: ReorderParams!) {
    reorderPhoto(params: $params){
    id
    position
    name
    filename
    thumb
    original
  }
}
`;

export const GET_BUSINESS_IMAGE_ID = gql`
  mutation blankPhotoId {
    blankPhotoId
  }
`;

export const CREATE_FEEDBACK_REPLY = gql`
  mutation createFeedbackReply($comment: String!, $id: Int!, $origin: String!) {
    createFeedbackReply(
      comment: $comment,
      id: $id,
      origin: $origin
    ) {
      message
      redirectUrl
      success
    }
  }
`;
