import {
  FC,
  memo,
  useState,
  useMemo
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { ProfileQuestionAnswer } from 'types/oneflare.com.au/businessPublicProfile';
import { buildFAQPageSchema } from '../../../../utils';
import { SectionStyled, TitleStyled } from '../../styled/BppBody';
import {
  FaqListStyled,
  FaqItemStyled,
  H3Styled,
  ParagraphStyled,
  AnchorStyled
} from './styled/Faq';

type Props = {
  faqs: Array<ProfileQuestionAnswer>;
};

const Faq: FC<Props> = ({ faqs }: Props) => {
  const filteredFaqs = useMemo(
    () => faqs?.filter(({ answer }) => answer),
    [faqs]
  );
  const [faqsShown, setFaqsShown] = useState(filteredFaqs.slice(0, 2));

  const faqsCount = filteredFaqs.length;
  const faqsShownCount = faqsShown.length;

  const handleClick = () => {
    if (faqsShownCount < faqsCount) {
      setFaqsShown(filteredFaqs.slice(0, faqsShownCount + 5));
    } else {
      setFaqsShown(filteredFaqs.slice(0, 2));
    }
  };

  return isEmpty(filteredFaqs) ? null : (
    <SectionStyled>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildFAQPageSchema(filteredFaqs))
        }}
      />
      <TitleStyled>Frequently asked questions</TitleStyled>
      <FaqListStyled>
        {faqsShown.map(({ id, answer, profileQuestion }) => answer && (
          <FaqItemStyled key={id}>
            <H3Styled>{profileQuestion?.question}</H3Styled>
            <ParagraphStyled>{answer}</ParagraphStyled>
          </FaqItemStyled>
        ))}
      </FaqListStyled>
      {faqsCount > 2 && (
        <AnchorStyled onClick={handleClick}>
          {faqsShownCount < faqsCount ? 'View more' : 'View less'}
        </AnchorStyled>
      )}
    </SectionStyled>
  );
};

export default memo(Faq);
