import { styled } from 'styled-components';
import Avatar from '@oneflare/flarekit/lib/components/Avatar';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Tag from '@oneflare/flarekit/lib/components/Tag';
import StarRating from 'shared/components/StarRating';
import Success from '@oneflare/flarekit/lib/components/icons/Success';
import TruncateText from '@oneflare/flarekit/lib/components/TruncateText';

export const ReviewHeaderWrapperStyled = styled.section`
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const AvatarStyled = styled(Avatar).attrs({
  avatarSize: {
    default: '56px'
  }
})`
  margin-inline-end: 16px;
  width: 56px;
  height: 56px;
`;

export const ColumnWrapperStyled = styled.div<{
  $grow?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: 90px;
  flex: ${({ $grow }) => ($grow ? 1 : 0)};
  ${({ $grow }) => !$grow && 'align-items: flex-end;'}
`;

export const ReviewerTagWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewerStyled = styled(Paragraph)`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TagStyled = styled(Tag).attrs({
  kind: 'infoFilled',
  size: 'small'
})`
  margin-left: 8px;
`;

export const StarRatingStyled = styled(StarRating)`
  margin-right: 8px;
  width: 100px;

  .star {
    height: 16px;
    width: 16px;
  }
`;

export const OriginStyled = styled(Paragraph)`
  font-size: 14px;
  margin-block-start: 4px;
`;

export const DateStyled = styled(Paragraph)`
  white-space: nowrap;
  font-size: 14px;
  color: ${({ theme }) => theme.color.spaceGrey};
`;

export const FlexWrapperStyled = styled.section`
  display: flex;
  align-items: center;
`;

export const SuccessStyled = styled(Success).attrs(({ theme }) => ({
  kind: 'solid',
  width: '16px',
  fill: theme.color.success
}))`
  width: 16px;
  margin-block-start: 4px;
  margin-inline-end: 4px;
`;

export const ParagraphStyled = styled(Paragraph)`
  font-size: 14px;
`;

export const CommentWrapperStyled = styled.section`
  border-radius: 8px;
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.tin};
  font-size: 16px;
`;

export const CommentFlexWrapperStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const CommenterStyled = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};
  margin-right: 16px;
  width: 100%;
`;

export const TruncateTextStyled = styled(TruncateText)`
  font-size: 16px;
`;

export const CommenterTruncateTextStyled = styled(TruncateText)`
  display: inline-flex;
  max-width: calc(100% - 54px);
`;

export const CommenterNameStyled = styled.span`
  color: ${({ theme }) => theme.color.charcoal};
`;

export const JobInfoStyled = styled(Paragraph)`
  margin-bottom: 8px;
`;
