import { styled, css } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Close from '@oneflare/flarekit/lib/components/icons/Close';
import CautionWarning from '@oneflare/flarekit/lib/components/icons/CautionWarning';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import H4 from '@oneflare/flarekit/lib/components/H4';

export const ErrorContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.color.cloud};

  ${media.lgUp`
    margin-top: 32px;
  `}
`;

export const ErrorHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 16px;
  border-radius: 4px 4px 0 0 ;
  background-color: ${({ theme }) => theme.color.tin};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px 0;

  ${media.lgUp`
    padding: 0 24px;
  `}
`;

export const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.tin};

  &:last-child {
    border-bottom: 0;
    margin: 0 0 8px;
  }

  ${({ showMobileOnly }) => !showMobileOnly && `

    &:first-child {
      display: none;
    }

    ${media.lgUp`
      flex-direction: row;
      width: 100%;

      &:first-child {
        display: flex;
        padding: 16px 0 0;
        border-bottom: 0;
      }
    `}
  `}
`;

const fontStyles = css`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.gunMetal};
`;

export const ErrorFilesStyled = styled.span`
  ${fontStyles};

  margin: 0 8px 0 12px;
`;

export const FileTitleStyled = styled(H4)`
  display: none;

  ${media.lgUp`
    display: block;
    flex: 1;
    ${fontStyles}
    font-weight: bold;
    margin-bottom: 0;
  `}
`;

export const ReasonTitleStyled = styled(FileTitleStyled)`
  ${media.lgUp`
    width: 408px;
    flex: none;
    margin-left: 16px;
  `}
`;

export const FileNameStyled = styled.div`
  width: 100%;
  margin: 0 0 4px;
  ${fontStyles}

  ${({ showMobileOnly }) => !showMobileOnly && `
    ${media.lgUp`
      flex: 1;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  `}
`;

export const ReasonStyled = styled(FileNameStyled)`
  color: ${({ theme }) => theme.color.silver};
  font-size: 14px;
  margin: 0;

  ${({ showMobileOnly }) => !showMobileOnly && `
    ${media.lgUp`
      width: 408px;
      flex: none;
      margin-left: 16px;
      font-size: 16px;
      color: ${({ theme }) => theme.color.gunMetal};
    `}
  `}
`;

export const LessOrMoreStyled = styled(Anchor)`
  ${fontStyles};

  color: ${({ theme }) => theme.color.infoDark};
`;

export const CloseStyled = styled(Close).attrs(({ theme }) => ({
  kind: 'hollow',
  fill: theme.color.gunMetal
}))`
  position: absolute;
  right: 16px;
  transform: translateY(4px);

  &:hover{
    cursor: pointer;
  }

  svg{
    width: 15px;
    height: 15px;
  }
`;

export const WarningIconStyled = styled(CautionWarning).attrs(({ theme }) => ({
  fill: theme.color.error,
  kind: 'solid'
}))`
  svg {
    background-color: transparent;
    height: 20px;
    max-width: 20px;
  }
`;
