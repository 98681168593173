import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';

export const OpeningHoursListStyled = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  max-width: 280px;
  color: ${({ theme }) => theme.color.gunMetal};
  justify-content: flex-start;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const OpeningHoursDayStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.color.gunMetal};
  font-weight: bold;
  width: 102px;
`;

export const ShiftWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShiftStyled = styled.span<{
  $closed?: boolean;
}>`
  color: ${({ theme, $closed }) => ($closed ? theme.color.silver : theme.color.gunMetal)};

  ${media.lgUp`
    text-align: left;
  `}
`;
