import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';

export const ProgressStyled = styled.div`
  display: none;

  ${media.lgUp`
    display: block;
    margin: 16px 0 8px;
    position: relative;
    border-radius: 3px;
    width: 100%;
    height: 6px;
    background-color: ${({ theme }) => theme.color.cloud};

    &:before {
      position: absolute;
      border-radius: 3px;
      width: ${({ progress }) => progress && `${progress}%`};
      height: 100%;
      background-color: ${({ theme }) => theme.color.link};
      content: '';
      color: #fff;
      text-align: center;
    }
  `}
`;
