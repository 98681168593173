import { styled } from 'styled-components';
import dynamic from 'next/dynamic';
import media from '@oneflare/flarekit/lib/libs/media';
import Breadcrumbs from 'shared/components/Breadcrumbs';

export const BusinessPublicProfileContainerStyled = styled.main`
  max-width: 1170px;
  margin: 24px 0 40px;

  ${media.mdUp`
    margin: 16px 0 64px;
  `}

  ${media.lgUp`
    padding: 0 16px;
    margin: 16px auto 64px;
  `}
`;

export const BreadcrumbsStyled = styled(Breadcrumbs)`
  text-align: right;
  font-size: 14px;
  margin: 0 16px 16px;
`;

export const BusinessPublicProfileLayoutWrapperStyled = styled.div`
  display: grid;

  ${media.mdUp`
    grid-template-columns: calc(100% / 3) 1fr;
  `}

  ${media.lgUp`
    grid-template-columns: 25% 50% 25%;
  `}
`;

export const ColumnStyled = styled.section`
  padding: 0 16px;
  max-width: 100vw;

  &:nth-of-type(3), &:last-of-type {
    ${media.mdUp`
      grid-column: 1 / span 2;
    `}

    ${media.lgUp`
      grid-column: auto;
    `}
  }
`;

// #region - React tooltip with customized styles
// Dynamically imported to avoid warning about prop did not match between server and client
const ReactTooltip = dynamic(() => import('react-tooltip').then(module => module.Tooltip), {
  ssr: false
});

export const ReactTooltipStyled = styled(ReactTooltip)`
  &&& {
    font-family: ${({ theme }) => theme.font.primaryRegular};
    font-size: 12px;
    opacity: 1;
    color: ${({ theme }) => theme.color.nickel};
    background: ${({ theme }) => theme.color.gunMetal};
    padding: 4px 12px;
    max-width: 228px;

    &::before{
      height: 0;
    }

    ${media.mdUp`
      max-width: 481px;
    `}
  }
`;
// #endregion
