import { memo, useMemo } from 'react';
import {
  H1Styled,
  H2Styled,
  H3Styled,
  H4Styled,
  H5Styled,
  H6Styled,
  SpanStyled,
  VerifiedBusinessStyled
} from './styled/BusinessNameHeading';

type BusinessNameHeadingProps = {
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  businessName: string;
  // For customizing heading styling, eg. font-size, text-align.
  headingStyles: Record<string, string> | React.CSSProperties;
  // For customizing verification icon styling. `top` is for vertical position adjustment.
  iconStyles: { width: string; height: string; top: string };
  verified: boolean;
};

const BusinessNameHeading = ({
  headingLevel,
  businessName,
  headingStyles,
  iconStyles,
  verified
} : BusinessNameHeadingProps) => {
  const nameWithoutLastChar = businessName.slice(0, -1);
  const nameLastChar = businessName.slice(-1);

 

  const HeadingComponent = useMemo(() => {
    const headingMapping = {
      h1: H1Styled,
      h2: H2Styled,
      h3: H3Styled,
      h4: H4Styled,
      h5: H5Styled,
      h6: H6Styled
    };
    return headingMapping[headingLevel];
  }, [headingLevel]);

  return (
    <HeadingComponent style={headingStyles}>
      {verified ? (
        <>
          {`${nameWithoutLastChar}`}
          <SpanStyled>
            {`${nameLastChar} `}
            <VerifiedBusinessStyled iconStyles={iconStyles} />
          </SpanStyled>
        </>
      ) : businessName}
    </HeadingComponent>
  );
};

export default memo(BusinessNameHeading);
