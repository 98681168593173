import { ImageUploadStyled, CloudUploaderStyled } from './styled/UploadFiles';

export const ACCEPTED_FILE_TYPES = {
  images: {
    acceptedFileTypes: ['.jpeg', '.png', '.jpg', '.gif', '.heic', '.heif'],
    fileTypeIcon: <ImageUploadStyled />
  },
  files: {
    acceptedFileTypes: ['.pdf', '.jpeg', '.jpg', '.png', '.heic', '.heif'],
    fileTypeIcon: <CloudUploaderStyled />
  },
  avatar: {
    acceptedFileTypes: ['.jpeg', '.jpg', '.png'],
    fileTypeIcon: null
  },
  invoice: {
    acceptedFileTypes: ['.jpeg', '.jpg', '.gif', '.png', '.pdf', '.heic', '.heif'],
    fileTypeIcon: null
  }
};
