import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Button from '@oneflare/flarekit/lib/components/Button';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';

export const ButtonWrapperStyled = styled.div`
  display: flex;
  padding: 0 24px;
  justify-content: space-around;

  ${media.smUp`
    padding: 0;
  `}
`;

export const TipsStyled = styled(Paragraph)`
  ${({ theme }) => theme.color.gunMetal};

  font-size: 16px;
  margin: 0;
`;

export const CancelButtonStyled = styled(Button)`
  width: 232px;
  margin-right: 16px;
`;
export const ConfirmButtonStyled = styled(Button)`
  width: 232px;
`;
