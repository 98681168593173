export const oneflarePopularCategories = [{
  title: 'Cleaning',
  image: 'https://www.oneflare.com.au/wp-content/uploads/2020/01/cleaner-pop-cat-all.jpg',
  url: '/cleaner'
}, {
  title: 'Removalist',
  image: 'https://www.oneflare.com.au/wp-content/uploads/2020/01/removalist-pop-cat-all.jpg',
  url: '/removalists'
}, {
  title: 'Plumber',
  image: 'https://www.oneflare.com.au/wp-content/uploads/2020/01/plumber-pop-cat-all-1.jpg',
  url: '/plumber'
}, {
  title: 'Accountant',
  image: 'https://www.oneflare.com.au/wp-content/uploads/2020/01/tax-accountant-pop-cat-all.jpg',
  url: '/accountant'
}, {
  title: 'Builder',
  url: '/builders'
}, {
  title: 'Photographer',
  url: '/photographer'
}, {
  title: 'Carpet cleaning',
  url: '/carpet-cleaning'
}, {
  title: 'Gardening',
  url: '/gardener'
}, {
  title: 'Painter',
  url: '/painters'
}, {
  title: 'Personal Trainer',
  url: '/personal-trainer'
}, {
  title: 'Electrician',
  url: '/electrician'
}, {
  title: 'Pest Control',
  url: '/pest-control'
}, {
  title: 'Kitchen Renovations',
  url: '/kitchen-renovations'
}, {
  title: 'Celebrant',
  url: '/celebrant'
}, {
  title: 'Air Conditioning and Heating',
  url: '/air-conditioning'
}, {
  title: 'Home Renovation',
  url: '/home-renovation'
}, {
  title: 'DJ',
  url: '/djs'
}, {
  title: 'Concreting',
  url: '/concreting'
}, {
  title: 'Tiler',
  url: '/tiling'
}, {
  title: 'Conveyancing',
  url: '/conveyancing'
}, {
  title: 'Landscaper',
  url: '/landscaping'
}, {
  title: 'Web Design',
  url: '/web-design'
}, {
  title: 'Window Cleaning',
  url: '/window-cleaning'
}, {
  title: 'Plasterer',
  url: '/plastering'
}];
