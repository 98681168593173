import {
  FC,
  memo,
  useMemo,
  ReactElement
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';
import { naturalLanguageJoin } from 'shared/utils/helpers';
import { renderFullStateName } from '@oneflare-page-components/BusinessPublicProfile/components/BppHeader/utils';
import { ReactTooltipStyled } from '@oneflare-page-components/BusinessPublicProfile/styled/BusinessPublicProfile';
import {
  SubSectionWrapperStyled,
  TitleStyled,
  DetailCopyStyled
} from '../../styled/BppHeader';
import {
  RowWrapperStyled,
  OnTimeStyled,
  CrownWrapperStyled,
  CrownStyled,
  MostHiredStyled,
  TopRatedStyled
} from './styled/BadgesAwards';

type Props = {
  business: BusinessPublicProfileModel;
}

type FormattedAwardsModel = Array<[string, { years: Array<string> }]>

const BadgesAwards: FC<Props> = ({
  business
}: Props) => {
  const awards = business?.awards;
  const badges = business?.badges;
  const featuredTiles = business?.featuredTiles;
  const state = business?.state;

  const formattedAwards: FormattedAwardsModel = useMemo(() => {
    return awards ? Object.entries(awards.reduce((acc, award) => {
      const years = acc[award.name]?.years || [];
      return ({
        ...acc,
        [award.name]: {
          years: [...years, award.year]
        }
      });
    }, {})) : null;
  }, [awards]);

  const hasOnTimeGuarantee: boolean = useMemo(() => (
    Boolean(badges && badges.filter(({ name }) => (name === 'On Time Guarantee')).length)
  ), [badges]);

  const awardIconMap: Record<string, ReactElement> = {
    'Top Rated': <TopRatedStyled />,
    'Most Hired': <MostHiredStyled />
  };

  if (isEmpty(featuredTiles) && !awards?.length && !hasOnTimeGuarantee) {
    return null;
  }

  return (
    <SubSectionWrapperStyled>
      <TitleStyled>
        Badges and awards
      </TitleStyled>
      {hasOnTimeGuarantee && (
        <RowWrapperStyled>
          <OnTimeStyled />
          <DetailCopyStyled>
            On-Time Guarantee
          </DetailCopyStyled>
        </RowWrapperStyled>
      )}
      {Boolean(formattedAwards?.length) && formattedAwards.map(([title, award]) => (
        <RowWrapperStyled key={title}>
          {awardIconMap[title]}
          <DetailCopyStyled>
            <span
              data-tooltip-content={`${title} in ${renderFullStateName(state)} for ${naturalLanguageJoin(award.years)}`}
              data-tooltip-id={`${title}-tooltip`}
            >
              {`${title} Award`}
            </span>
          </DetailCopyStyled>
          <ReactTooltipStyled id={`${title}-tooltip`} />
        </RowWrapperStyled>
      ))}
      {!isEmpty(featuredTiles) && (
        <RowWrapperStyled>
          <CrownWrapperStyled>
            <CrownStyled />
          </CrownWrapperStyled>
          <DetailCopyStyled>
            <span
              data-tooltip-id="featured-tooltip"
              data-tooltip-content={`This business is a Top Featured Business for 
              ${featuredTiles[0]?.category?.verb} in 
              ${naturalLanguageJoin(featuredTiles.map((featuredTile) => featuredTile?.location?.suburb))}`}
            >
              Top Featured Business
            </span>
          </DetailCopyStyled>
          <ReactTooltipStyled id="featured-tooltip" />
        </RowWrapperStyled>
      )}
    </SubSectionWrapperStyled>
  );
};

export default memo(BadgesAwards);
