import H5 from '@oneflare/flarekit/lib/components/H5';
import H6 from '@oneflare/flarekit/lib/components/H6';
import Star from '@oneflare/flarekit/lib/components/icons/Star';
import Oneflare from '@oneflare/flarekit/lib/components/logos/2018/Oneflare';
import Facebook from '@oneflare/flarekit/lib/components/logos/Facebook';
import Google from '@oneflare/flarekit/lib/components/logos/Google';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import StarRating from '@oneflare/flarekit/lib/components/StarRating';
import TruncateText from '@oneflare/flarekit/lib/components/TruncateText';
import { styled } from 'styled-components';

export const RatingWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const RatingAvgStyled = styled(H5)`
  font-size: 22px;
  margin-right: 4px;
`;

export const StarRatingStyled = styled(StarRating).attrs(() => ({
  starStyles: {
    xs: {
      size: '18px',
      spacing: '3px'
    }
  }
}))`
  position: relative;
  bottom: 2px;
`;

export const ParagraphStyled = styled(Paragraph)`
  font-size: 13px;
  color: ${({ theme }) => theme.color.spaceGrey};
`;

export const OriginsListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 16px 0 0;
  width: 100%;
`;

export const OriginItemStyled = styled.li`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const OriginDetailsStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const OneflareStyled = styled(Oneflare).attrs(() => ({
  kind: 'logo',
  width: '16px'
}))``;

export const FacebookStyled = styled(Facebook).attrs(() => ({
  width: '16px'
}))``;

export const GoogleStyled = styled(Google).attrs(() => ({
  kind: 'G',
  width: '16px'
}))``;


export const SubRatingAvgStyled = styled(H6)`
  margin-left: 8px;
  width: 32px;
`;

export const StarStyled = styled(Star).attrs(({ theme }) => ({
  fillStar: theme.color.warning,
  width: '17px',
  height: '16px'
}))`
  position: relative;
  height: 16px;
  bottom: 2px;
  margin-left: 4px;
`;

export const TruncateTextStyled = styled(TruncateText)`
  color: ${({ theme }) => theme.color.link};
`;
