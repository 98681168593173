import { Dispatch, SetStateAction, memo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { GET_BUSINESS_IMAGE_ID } from 'mutations/shared/businessProfile';
import { naturalLanguageJoin } from 'shared/utils/helpers';
import { useBreakpoint } from 'shared/utils/hooks';
import { S3_SIGNED_URL } from 'queries/shared/getS3SignedUrl';
import { ACCEPTED_FILE_TYPES } from './constants';
import {
  DropContainer,
  BrowseStyled,
  ParagraphStyled,
  PlaceholderStyled,
  WrapperStyled
} from './styled/UploadFiles';
import { TempFileModel, uploadFiles } from '../../utils/uploadFiles';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';

const defaultProps = {
  children: null,
  className: '',
  customFileTypes: [],
  filesList: [],
  hasSingleFileLimit: false,
  hideAcceptedFileTypes: false,
  holdUpload: false,
  willReorderOnServer: false,
  mutation: () => {},
  pageType: 'images',
  pageTitle: 'Image Gallery',
  setErrorFileList: () => {},
  setFile: () => {},
  setFilesList: () => {}
};

type UploadFileProps = {
  children?: React.ReactNode;
  className?: string;
  customFileTypes?: Array<string>;
  filesList?: Array<any>;
  hasSingleFileLimit?: boolean;
  hideAcceptedFileTypes?: boolean;
  holdUpload?: boolean;
  mutation?: (arg0?: TempFileModel) => any;
  pageType?: string;
  pageTitle?: string;
  setErrorFileList?: Dispatch<SetStateAction<any>>;
  setFile?: Dispatch<SetStateAction<any>>;
  setFilesList?: Dispatch<SetStateAction<any>>;
  willReorderOnServer?: boolean;
};

const UploadFiles = ({
  filesList, mutation, setFilesList, setFile, pageTitle, customFileTypes,
  pageType, className, setErrorFileList, hasSingleFileLimit,
  children, willReorderOnServer, hideAcceptedFileTypes, holdUpload
}: UploadFileProps) => {
  const [fileFormat, setFileFormat] = useState('jpg');

  const fileCategory = () => {
    switch (pageType) {
      case 'files':
        return 'verification_documents';
      case 'avatar':
        return 'avatars';
      case 'invoice':
        return 'feedback_attachments';
      default:
        return 'portfolios';
    }
  };

  const { error, refetch } = useQuery(S3_SIGNED_URL, {
    variables: {
      fileFormat,
      fileCategory: fileCategory()
    }
  });

  const isLgUp = useBreakpoint('lg');
  const fileTypes = customFileTypes.length
    ? customFileTypes : ACCEPTED_FILE_TYPES[pageType].acceptedFileTypes;

  const formattedFileTypes = fileTypes.map((type) => type.toUpperCase().replace(/\./g, ''));

  const convertToString = (types) => types.join(', ');

  const type = pageType === 'images' ? 'Image' : 'File';
  const lowerCaseFileTypes = convertToString(formattedFileTypes).toLowerCase();

  const files = () => {
    const newFileTypes = fileTypes.map((i) => i.replace('.', 'image/'));

    const acceptedFormat = Object.assign.apply(
      null,
      newFileTypes.map((fileType) => ({ [fileType]: fileTypes }))
    );
    return acceptedFormat;
  };

  const getPlaceholder = () => {
    if (isLgUp) {
      return (
        <ParagraphStyled>
          {`Drag and drop ${pageType} here or `}
          <BrowseStyled>browse</BrowseStyled>
        </ParagraphStyled>
      );
    }
    return (
      <ParagraphStyled>
        <BrowseStyled>Browse</BrowseStyled>
        {` ${pageType} to upload`}
      </ParagraphStyled>
    );
  };

  const [getImageId] = useMutation(GET_BUSINESS_IMAGE_ID, {
    onError: (errorMessage) => DataDogRumAgent.addRumError(errorMessage, `Oneflare | Shared | ${pageTitle}`)
  });

  const handleUpload = async (index, tempFilesList) => {
    const params = {
      index,
      tempFilesList,
      filesList,
      setFile,
      setFilesList,
      setErrorFileList,
      mutation
    };
    const { errors } = await uploadFiles(params);
    if (errors) DataDogRumAgent.addRumError(errors, `Oneflare | ${pageTitle} | Upload failed with AWS`);
  };

  const onDrop = async (files, rejectedFiles) => {
    const formattedRejectedFiles = rejectedFiles.map(({ file }) => ({
      fileName: file.name,
      error: `${type} type incorrect, please upload ${lowerCaseFileTypes} only`
    }));

    setErrorFileList(formattedRejectedFiles);

    const tempFilesList = files.map((file) => ({
      previewUrl: URL.createObjectURL(file),
      file,
      documentOriginalFileName: file.name
    }));

    setFilesList([...tempFilesList, ...filesList]);

    const filesLength = files.length;
    /* eslint-disable no-await-in-loop */
    for (let i = filesLength - 1; i >= 0; i -= 1) {
      setFileFormat(files[i].type.split('/')[1]);
      if (willReorderOnServer) {
        const { data: { blankPhotoId: id } } = await getImageId();
        const file = tempFilesList[i];
        file.id = id;
      }
      const { data: s3Data } = await refetch();
      const signS3 = s3Data?.signS3;
      tempFilesList[i].signS3Urls = signS3;
      setFilesList([...tempFilesList, ...filesList]);
      if (!holdUpload) handleUpload(i, tempFilesList);
    }
    /* eslint-enable no-await-in-loop */
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: files(),
    multiple: !hasSingleFileLimit
  });

  if (error) {
    DataDogRumAgent.addRumError(error, `Oneflare | Shared | ${pageTitle}`);
    return null;
  }

  return (
    children ? (
      <WrapperStyled
        data-testid="upload-files-component"
        className={className}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        {children}
        <input {...getInputProps()} />
      </WrapperStyled>
    ) : (
      <DropContainer
        className={className}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {ACCEPTED_FILE_TYPES[pageType].fileTypeIcon}
        {getPlaceholder()}
        {!hideAcceptedFileTypes && (
        <PlaceholderStyled>
          {`File types accepted: ${naturalLanguageJoin(formattedFileTypes)}`}
        </PlaceholderStyled>
        )}
      </DropContainer>
    )
  );
};

UploadFiles.defaultProps = defaultProps;

export default memo(UploadFiles);
