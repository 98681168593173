import { styled } from 'styled-components';
import TextArea from '@oneflare/flarekit/lib/components/TextArea';
import Button from '@oneflare/flarekit/lib/components/Button';

export const ReplyToReviewWrapperStyled = styled.div`
  margin-top: 12px;
`;

export const TextAreaStyled = styled(TextArea)`
  margin-top: 8px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;
