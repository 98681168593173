import { styled } from 'styled-components';
import Loader from '@oneflare/flarekit/lib/components/Loader';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

export const LoaderWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoaderStyled = styled(Loader).attrs(({ theme }) => ({
  kind: 'threeDots',
  fill: theme.color.aluminium
}))`
  width: 40px;
  height: 40px;
`;

export const ReviewListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ReviewWrapperStyled = styled.li<{
  $index?: number;
}>`
  padding: 32px 0;
  ${({ theme, $index }) => ($index && $index > 0 && `border-top: 1px solid ${theme.color.tin}`)};

  &:last-of-type {
    padding: 32px 0 16px;
  }
`;

export const AnchorStyled = styled(Anchor)`
  font-size: 16px;
`;
