import type { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import type { FunctionComponent } from 'react';
import { memo, useState } from 'react';
import {
  SubSectionWrapperStyled,
  TitleStyled,
  TruncateCopyStyled
} from '../../styled/BppHeader';

type DescriptionProps = {
  description: string;
  tracker: BusinessProfileTrackerModel;
};

const Description: FunctionComponent<DescriptionProps> = ({ description, tracker }) => {
  const [isNewline, setIsNewline] = useState<boolean>(false);

  const handleExpand = () => {
    setIsNewline(true);
    tracker.interaction('descriptionReadMore');
  };

  if (!description) return null;
  return (
    <SubSectionWrapperStyled>
      <TitleStyled>
        Business description
      </TitleStyled>
      <TruncateCopyStyled
        text={description}
        lines={4}
        expandable
        $isNewline={isNewline}
        onExpand={handleExpand}
      />
    </SubSectionWrapperStyled>
  );
};

export default memo(Description);
