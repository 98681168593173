import { gql } from '@apollo/client';

export const S3_SIGNED_URL = gql`
  query SignS3($fileFormat: String!, $fileCategory: String!){
    signS3(fileFormat: $fileFormat, fileCategory: $fileCategory) {
      signedUrl
      objectUrl
    }
  }
`;
