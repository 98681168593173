import CollapsibleContainer from '@oneflare/flarekit/lib/components/CollapsibleContainer';
import type { FunctionComponent, MutableRefObject } from 'react';
import {
  memo,
  useState,
  useEffect
} from 'react';
import { isMobile } from 'react-device-detect';

import type { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import { useBreakpoint } from 'shared/utils/hooks';
import type { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';

import {
  Profile,
  RatingSummary,
  ContactDetails,
  SocialMedia,
  OpeningHours,
  Description,
  BadgesAwards,
  AcceptedPayments,
  Services,
  NearbyLocations
} from './components';
import {
  BppHeaderWrapperStyled,
  ButtonStyled
} from './styled/BppHeader';

type BppHeaderProps = {
  business: BusinessPublicProfileModel;
  businessSlug: string;
  isStickyHeader: boolean;
  staticRender: boolean;
  reviewsSectionRef: MutableRefObject<HTMLElement | null>;
  tracker: BusinessProfileTrackerModel;
};

const BppHeader: FunctionComponent<BppHeaderProps> = ({
  business,
  businessSlug,
  staticRender,
  isStickyHeader,
  reviewsSectionRef,
  tracker
}) => {
  const isLgUp = useBreakpoint('lg');
  const [isExpanded, setIsExpanded] = useState(() => {
    if (staticRender) return true;
    if (isMobile) return false;
    return true;
  });

  const [showNearbyLocations, setShowNearbyLocations] = useState(() => {
    if (isMobile) return false;
    return true;
  });

  const [showExpandedButton, setShowExpandedButton] = useState(() => {
    if (isMobile) return true;
    return false;
  });

  useEffect(() => {
    setShowNearbyLocations(isLgUp);
    setShowExpandedButton(!isLgUp);
    setIsExpanded(isLgUp);
  }, [isLgUp]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <BppHeaderWrapperStyled>
      <Profile
        business={business}
        businessSlug={businessSlug}
        isStickyHeader={isStickyHeader}
        reviewsSectionRef={reviewsSectionRef}
        tracker={tracker}
      />
      <RatingSummary
        feedbackAvg={business.feedbackAvg}
        facebookReviewsStats={business.facebookReviewsStats}
        googleReviewsStats={business.googleReviewsStats}
        oneflareReviewsStats={business.oneflareReviewsStats}
        facebookReviewsUrl={business.facebookReviewsUrl}
        googleReviewsUrl={business.googleReviewsUrl}
        isStickyHeader={isStickyHeader}
        reviewsSectionRef={reviewsSectionRef}
      />
      <ContactDetails business={business} tracker={tracker} />
      <CollapsibleContainer expanded={isExpanded}>
        <SocialMedia socialMediaLinks={business.socialMediaLinks} />
        <OpeningHours business={business} />
        <Description description={business.description} tracker={tracker} />
        <BadgesAwards business={business} />
        <AcceptedPayments acceptedPaymentMethods={business.acceptedPaymentMethods} />
        {showNearbyLocations && business.primaryCategory && (
          <NearbyLocations
            category={business.primaryCategory.name}
            nearbyCategoryLocations={business.nearbyCategoryLocations}
          />
        )}
        <Services serviceTypes={business.serviceTypes} />
      </CollapsibleContainer>
      {showExpandedButton && (
        <ButtonStyled
          kind="outline-dark-sm"
          label={isExpanded ? 'See less' : 'See more'}
          onClick={handleClick}
        />
      )}
    </BppHeaderWrapperStyled>
  );
};

export default memo(BppHeader);
