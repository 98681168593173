import Tracker from '@oneflare/flarekit/lib/libs/Tracker/Tracker';
import {
  BusinessPublicProfileModel,
  BusinessPublicProfileReviewModel,
  OpeningHoursModel,
  ProfileQuestionAnswer
} from 'types/oneflare.com.au/businessPublicProfile';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { getCloudFrontImageUrl } from 'shared/utils/cloudFrontImageResize';
import {
  convert12HrTimeTo24HrTime,
  convertShortToFullDayOfWeek,
  squish
} from 'shared/utils/helpers';
import { renderPaymentMethodsLabel } from '../components/BppHeader/utils';

// Structured data: LocalBusiness
export const buildLocalBusinessSchema = (
  business: BusinessPublicProfileModel,
  initialReviewData: BusinessPublicProfileReviewModel
) => {
  const {
    abn,
    acceptedPaymentMethods,
    address,
    awards,
    description,
    feedbackAvg,
    feedbackCount,
    gallery,
    landline,
    logoFileName,
    logoId,
    name,
    openingHours,
    phone,
    postcode,
    qualifications,
    state,
    suburb,
    yearFounded,
    website
  } = business;
  const reviews = initialReviewData?.reviews;

  const formattedAwards = Array.from(new Set(awards?.map(({ name }) => `${name} Award`)));

  const businessAvatar = publicRuntimeConfig.ENVIRONMENT === 'development'
    ? `${publicRuntimeConfig.SITE_URL}/system/avatars/${logoId}/profile/${logoFileName}`
    : getCloudFrontImageUrl({ key: `avatars/${logoId}/original/${logoFileName}`, bucket: 'oneflare-production' });

  // #region - Process openingHours for openingHoursSpecification
  const formatOpenHours = ({
    closed, formattedOpenHours: openHours, openStatus
  }: OpeningHoursModel) => {
    const closesIndex = openHours.length === 1 ? 0 : 1;
    switch (true) {
      case (closed === true):
        return ['00:00', '00:00'];
      case (openStatus === '24 hours'):
        return ['00:00', '23:59'];
      case (!closed && !openHours.length):
        return ['09:00', '17:00'];
      default:
        return [
          convert12HrTimeTo24HrTime(openHours[0][0]),
          // Opening hours can have two split shifts for a day,
          // in which case there will be two elements within `formattedOpenHours`.
          // Since `OpeningHoursSpecification` type doesn't support split shifts atm,
          // starting time of first shift and ending time of second shift are used.
          convert12HrTimeTo24HrTime(openHours[closesIndex][1])
        ];
    }
  };

  const getOpeningHoursSpecification = (openingHours: Array<OpeningHoursModel>) => {
    const openHoursMap = openingHours.reduce((prev, curr) => {
      const [opens, closes] = formatOpenHours(curr);
      const key = `${opens}-${closes}`;
      const value = prev.get(key);
      const fullDay = convertShortToFullDayOfWeek(curr.day);
      if (value) {
        value.dayOfWeek.push(fullDay);
      } else {
        prev.set(key, {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [fullDay],
          opens,
          closes
        });
      }
      return prev;
    }, new Map());
    return Array.from(openHoursMap.values());
  };
  // #endregion

  const filteredPaymentMethods = Object.keys(acceptedPaymentMethods).filter(
    (key) => acceptedPaymentMethods[key] === true
  );

  const localBusinessSchema: Record<string, any> = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'AU',
      addressLocality: suburb,
      addressRegion: state,
      postalCode: postcode,
      streetAddress: address
    },
    award: formattedAwards,
    description: squish(description),
    foundingDate: yearFounded,
    hasCredential: qualifications?.map(({ name, number, type }) => ({
      '@type': 'EducationalOccupationalCredential',
      credentialCategory: type,
      identifier: number,
      name
    })),
    identifier: abn,
    image: gallery?.map(({ original }) => original),
    logo: website ? businessAvatar : null,
    name,
    openingHoursSpecification: getOpeningHoursSpecification(openingHours),
    paymentAccepted: filteredPaymentMethods.map((method) => renderPaymentMethodsLabel(method)),
    review: reviews?.map(({
      avatarUrl,
      postedAt,
      rating,
      reviewerName,
      reviewText
    }) => ({
      '@type': 'Review',
      author: {
        '@type': 'Person',
        image: avatarUrl,
        name: reviewerName
      },
      datePublished: postedAt,
      reviewBody: squish(reviewText),
      reviewRating: {
        '@type': 'Rating',
        bestRating: 5,
        ratingValue: rating,
        worstRating: 1
      }
    })),
    telephone: [phone, landline].filter((item) => item).map((item) => `+61 ${item}`),
    url: website
  };

  if (feedbackCount) {
    localBusinessSchema.aggregateRating = {
      '@type': 'AggregateRating',
      bestRating: 5,
      reviewCount: feedbackCount,
      ratingValue: feedbackAvg,
      worstRating: 1
    };
  }

  return localBusinessSchema;
};

// Structured data: FAQPage
export const buildFAQPageSchema = (faqs: Array<ProfileQuestionAnswer>) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqs.map(({ answer, profileQuestion }) => ({
    '@type': 'Question',
    name: profileQuestion?.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: squish(answer)
    }
  }))
});

// #region - Interaction tracking
type InteractionType = 'abn' | 'descriptionReadMore' | 'pageView' | 'phone' | 'portfolio' | 'reviewReadMore' | 'website' | 'dbm';
type TrackerModel = typeof Tracker;
export interface BusinessProfileTrackerModel extends TrackerModel {
  tracks: Record<string | InteractionType, Record<string, any>>;
  interaction: (name: InteractionType, data?: Record<string, any>) => void;
}

export const buildBusinessProfileTracker = (businessId: number): BusinessProfileTrackerModel => {
  Tracker.setTracks({
    // ABN link click
    abn: {
      action: 'click_text',
      business_interaction_id: businessId,
      category: 'abn_number',
      event: 'interaction',
      label: 'business_profile'
    },
    // Description "Read more" click
    descriptionReadMore: {
      action: 'read_more',
      business_interaction_id: businessId,
      category: 'business_description',
      event: 'interaction',
      label: 'business_profile'
    },
    // Page view
    pageView: {
      action: 'hidden',
      business_interaction_id: businessId,
      category: 'page_visibility',
      event: 'interaction',
      label: null,
      page_type: 'business_profile'
    },
    // Phone number click to expand full text and call
    phone: {
      action: null,
      business_interaction_id: businessId,
      category: 'phone_number',
      event: 'interaction',
      label: 'business_profile'
    },
    // Portfolio image(s) click
    portfolio: {
      action: null,
      business_interaction_id: businessId,
      category: 'portfolio_photo',
      event: 'interaction',
      label: 'business_profile',
      value: null
    },
    // Reviews "Read more" click
    reviewReadMore: {
      action: 'read_more',
      business_interaction_id: businessId,
      category: 'business_review',
      event: 'interaction',
      label: 'business_profile',
      value: null
    },
    // View website click
    website: {
      action: 'click_text',
      business_interaction_id: businessId,
      category: 'view_website',
      event: 'interaction',
      label: 'business_profile'
    },
    // Direct messaging button click
    dbm: {
      action: 'click',
      business_interaction_id: businessId,
      category: 'dbm_open',
      category_id: null,
      event: 'interaction',
      label: 'dbm'
    }
  });
  return Tracker;
};
// #endregion

export const compareDate = (postedAt: string) => {
  const dateOne = new Date();
  const dateTwo = new Date(String(postedAt));
  const dateOneUTC = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate());
  const dateTwoUTC = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate());
  const difference = (dateOneUTC - dateTwoUTC) / (1000 * 60 * 60 * 24);
  return difference;
};
