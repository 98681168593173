import { styled } from 'styled-components';
import RightArrow from '@oneflare/flarekit/lib/components/icons/RightArrow';

export const ListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 16px 0 0;
`;

export const ListItemStyled = styled.li`
  margin-top: 8px;
  display: flex;
`;

export const RightArrowStyled = styled(RightArrow).attrs(({ theme }) => ({
  fill: theme.color.success,
  width: '18px',
  height: '16px'
}))`
  margin-right: 12px;
  position: relative;
  top: 4px;
`;
