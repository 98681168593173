import { memo } from 'react';
import PropTypes from 'prop-types';
import ModalNew from '@oneflare/flarekit/lib/components/ModalNew';
import {
  ButtonWrapperStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  TipsStyled
} from './styled/ComfirmCancelModal';

const ConfirmCancelModal = ({
  confirmDisabled,
  isModalShow,
  title,
  message,
  isLoading,
  cancelLabel,
  confirmLabel,
  handleCancel,
  handleConfirm
}) => {
  const getFooterChildren = (
    <ButtonWrapperStyled>
      <CancelButtonStyled
        kind="md"
        label={cancelLabel}
        onClick={handleCancel}
      />
      <ConfirmButtonStyled
        kind="primary"
        disabled={confirmDisabled}
        label={confirmLabel}
        onClick={handleConfirm}
        isLoading={isLoading}
      />
    </ButtonWrapperStyled>
  );

  return (
    <ModalNew
      isCloseable
      show={isModalShow}
      onClose={handleCancel}
      title={title}
      footerChildren={getFooterChildren}
    >
      <TipsStyled>{message}</TipsStyled>
    </ModalNew>
  );
};

const propTypes = {
  cancelLabel: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  isModalShow: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  title: PropTypes.string
};

const defaultProps = {
  isModalShow: false,
  isLoading: false,
  cancelLabel: '',
  confirmLabel: '',
  confirmDisabled: false,
  handleCancel: () => {},
  handleConfirm: () => {},
  title: '',
  message: ''
};

ConfirmCancelModal.propTypes = propTypes;

ConfirmCancelModal.defaultProps = defaultProps;

export default memo(ConfirmCancelModal);
