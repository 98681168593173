import {
  memo, useState, useCallback, useEffect
} from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import ErrorItem from './components/ErrorItem';
import {
  ErrorContainerStyled,
  ErrorHeader,
  ErrorFilesStyled,
  LessOrMoreStyled,
  WarningIconStyled,
  CloseStyled,
  ContentWrapper,
  ItemStyled,
  FileTitleStyled,
  ReasonTitleStyled
} from './styled/ErrorFileList';

const ErrorFileList = ({ errorFileList, setErrorFileList, showMobileOnly }) => {
  const errorFileLength = errorFileList.length;
  const [seeMore, setSeeMore] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(!!errorFileLength);
  }, [setIsShow, errorFileLength]);

  const toggleSeeMore = useCallback(() => {
    setSeeMore(!seeMore);
  }, [seeMore, setSeeMore]);

  const handleClose = () => {
    setIsShow(false);
    setSeeMore(false);
    setErrorFileList([]);
  };

  const getErrorFileList = () => (
    <ContentWrapper>
      {!showMobileOnly && (
        <ItemStyled>
          <FileTitleStyled>File name</FileTitleStyled>
          <ReasonTitleStyled>Reason</ReasonTitleStyled>
        </ItemStyled>
      )}
      {errorFileList.map(({ fileName, error }, index) => (
        <ErrorItem
          key={`${fileName}${String(index)}`}
          fileName={fileName}
          error={error}
          showMobileOnly={showMobileOnly}
        />
      ))}
    </ContentWrapper>
  );

  if (!isShow) return null;

  return (
    <ErrorContainerStyled>
      <ErrorHeader>
        <WarningIconStyled />
        <ErrorFilesStyled>
          {`${errorFileLength} failed ${pluralize('upload', errorFileLength)}.`}
        </ErrorFilesStyled>
        <LessOrMoreStyled onClick={toggleSeeMore}>
          {seeMore ? 'See less' : 'See more'}
        </LessOrMoreStyled>
        <div tabIndex={0} onKeyPress={handleClose} onClick={handleClose} role="button">
          <CloseStyled />
        </div>
      </ErrorHeader>
      {seeMore && getErrorFileList()}
    </ErrorContainerStyled>
  );
};

const propTypes = {
  errorFileList: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.string,
      fileName: PropTypes.string
    })
  ),
  setErrorFileList: PropTypes.func,
  showMobileOnly: PropTypes.bool
};

const defaultProps = {
  errorFileList: {
    error: '',
    fileName: ''
  },
  setErrorFileList: () => {},
  showMobileOnly: false
};

ErrorFileList.propTypes = propTypes;

ErrorFileList.defaultProps = defaultProps;

export default memo(ErrorFileList);
