import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface FileModel {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

interface SignS3UrlsModel {
  objectUrl: string;
  signedUrl: string;
}

export interface TempFileModel {
  documentOriginalFileName: string;
  file: FileModel;
  percentage: number;
  previewUrl: string;
  signS3Urls: SignS3UrlsModel;
  thumb: string;
}

interface UploadFileProps {
  index: number;
  tempFilesList: Array<TempFileModel>;
  filesList: Array<any>;
  setFile: Dispatch<SetStateAction<any>>;
  setFilesList: Dispatch<SetStateAction<any>>;
  setErrorFileList: Dispatch<SetStateAction<any>>;
  mutation: (arg0?: TempFileModel) => any;
}

interface Response {
  success?: boolean;
  errors?: string;
}

export const uploadFiles = async ({
  index,
  tempFilesList,
  filesList = [],
  setFile = () => {},
  setFilesList = () => {},
  setErrorFileList = () => {},
  mutation = () => {}
}: Partial<UploadFileProps>) => {
  const data: Response = {};
  const list = tempFilesList;
  const currentFile = list[index];
  const fileType = currentFile.file.type;
  const currentSignS3 = currentFile.signS3Urls;

  const config = {
    headers: {
      'Content-Type': currentFile.file.type
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
      const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      currentFile.percentage = progress;
      setFile(currentFile);
      setFilesList([...list, ...filesList]);
    }
  };

  await axios.put(currentSignS3.signedUrl, currentFile.file, config)
    .then(async () => {
      data.success = true;
      currentFile.percentage = 100;
      currentFile.thumb = currentSignS3.objectUrl;
      await mutation(currentFile);
      setFilesList([...list, ...filesList]);
    })
    .catch((errMessage) => {
      const errorFile = {
        fileName: currentFile.file.name,
        error: `${fileType} failed to upload`
      };
      setErrorFileList(errorFile);
      data.errors = errMessage;
    });
  return data;
};
