import {
  FC, Fragment, memo, useMemo
} from 'react';
import { AcceptedPaymentMethodsModel } from 'types/oneflare.com.au/businessPublicProfile';
import * as Utils from '../../utils';
import {
  SubSectionWrapperStyled,
  TitleStyled
} from '../../styled/BppHeader';
import {
  AcceptedPaymentsWrapperStyled,
  PaymentLogoWrapperStyled,
  RowWrapperStyled,
  LabelStyled
} from './styled/AcceptedPayments';

type Props = {
  acceptedPaymentMethods: AcceptedPaymentMethodsModel;
}

const AcceptedPayments: FC<Props> = ({ acceptedPaymentMethods }: Props) => {
  const filteredPaymentMethods = useMemo(() => (
    acceptedPaymentMethods
      ? Object.entries(acceptedPaymentMethods).filter(([, accepted]) => accepted === true)
      : null
  ), [acceptedPaymentMethods]);

  if (!acceptedPaymentMethods || !filteredPaymentMethods?.length) return null;

  return (
    <SubSectionWrapperStyled>
      <TitleStyled>
        Accepted payment methods
      </TitleStyled>
      <RowWrapperStyled>
        {filteredPaymentMethods.map(([method]) => (
          <Fragment key={method}>
            <AcceptedPaymentsWrapperStyled>
              <PaymentLogoWrapperStyled>
                {Utils.renderPaymentMethodsIcon(method)}
              </PaymentLogoWrapperStyled>
              <LabelStyled>{Utils.renderPaymentMethodsLabel(method)}</LabelStyled>
            </AcceptedPaymentsWrapperStyled>
          </Fragment>
        ))}
      </RowWrapperStyled>
    </SubSectionWrapperStyled>
  );
};

export default memo(AcceptedPayments);
