import {
  FC,
  memo,
  useState,
  useMemo,
  MutableRefObject
} from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { GET_REVIEWS } from 'queries/shared/reviews';
import {
  BusinessPublicProfileReviewModel,
  ReviewResponseModel,
  ReviewRequestModel
} from 'types/oneflare.com.au/businessPublicProfile';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { useBreakpoint } from 'shared/utils/hooks';
import { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import Review from './components/Review';
import { SectionStyled, TitleStyled } from '../../styled/BppBody';
import {
  LoaderWrapperStyled,
  LoaderStyled,
  ReviewListStyled,
  ReviewWrapperStyled,
  AnchorStyled
} from './styled/Reviews';

type Props = {
  businessId: number;
  businessName: string;
  initialReviewData: BusinessPublicProfileReviewModel;
  isSameBusiness: boolean;
  reviewsSectionRef: MutableRefObject<HTMLElement | null>;
  tracker: BusinessProfileTrackerModel;
};

const Reviews: FC<Props> = ({
  businessId,
  businessName,
  initialReviewData,
  isSameBusiness,
  reviewsSectionRef,
  tracker
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [
    localReviewData,
    setLocalReviewData
  ] = useState<BusinessPublicProfileReviewModel>(initialReviewData);
  // Flag for telling whether fetchMore is to update reply or to fetch more reviews.
  const [isUpdateReply, setIsUpdateReply] = useState(false);

  const isLgUp = useBreakpoint('lg');
  const reviewTruncateLines = isLgUp ? 3 : 7;

  const pagesCount = useMemo(() => localReviewData?.pagesCount, [localReviewData?.pagesCount]);
  const currentPage = useMemo(() => localReviewData?.currentPage, [localReviewData?.currentPage]);
  const reviewCount = useMemo(() => localReviewData?.reviewCount, [localReviewData?.reviewCount]);
  const reviews = useMemo(() => localReviewData?.reviews ?? [], [localReviewData?.reviews]);

  const {
    fetchMore,
    networkStatus
  } = useQuery<ReviewResponseModel, ReviewRequestModel>(
    GET_REVIEWS,
    {
      variables: {
        businessId: Number(businessId),
        perPage: 5,
        page: 1
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setLocalReviewData(data?.getReviews);
        if (isUpdateReply) setIsUpdateReply(false);
      },
      onError: (err) => DataDogRumAgent.addRumError(err, 'Oneflare | Business Public Profile | GET_REVIEWS query')
    }
  );

  const fetchMoreRequestLoading = networkStatus === NetworkStatus.fetchMore;

  const handleFetchMore = () => {
    try {
      fetchMore({
        variables: {
          page: page + 1
        }
      });
      setPage((prev) => prev + 1);
    } catch (err) {
      DataDogRumAgent.addRumError(err, 'Oneflare | Busiess Public Profile | GET_REVIEWS fetchMore');
    }
  };

  const updateReply = (index: number) => {
    setIsUpdateReply(true);
    try {
      fetchMore({
        variables: {
          page: Math.ceil((index + 1) / 5) // Page where the submitted reply is located
        }
      });
    } catch (err) {
      DataDogRumAgent.addRumError(err, 'Oneflare | Busiess Public Profile | GET_REVIEWS fetchMore');
    }
  };

  return (
    reviewCount ? (
      <SectionStyled id="reviewAnchor" ref={reviewsSectionRef}>
        <TitleStyled>{`Reviews (${reviewCount})`}</TitleStyled>
        <ReviewListStyled>
          {reviews?.map((
            {
              id,
              reviewerName,
              uuid,
              reviewText,
              postedAt,
              rating,
              comment,
              verified,
              origin,
              avatarUrl,
              jobTitle,
              location
            },
            index
          ) => (
            <ReviewWrapperStyled key={uuid} $index={index}>
              <Review
                avatarUrl={avatarUrl}
                businessName={businessName}
                comment={comment}
                id={id}
                index={index}
                isSameBusiness={isSameBusiness}
                jobTitle={jobTitle}
                location={location}
                origin={origin}
                postedAt={postedAt}
                rating={rating}
                reviewerName={reviewerName}
                reviewText={reviewText}
                reviewTruncateLines={reviewTruncateLines}
                tracker={tracker}
                updateReply={updateReply}
                verified={verified}
              />
            </ReviewWrapperStyled>
          ))}
        </ReviewListStyled>
        {fetchMoreRequestLoading && !isUpdateReply && (
          <LoaderWrapperStyled>
            <LoaderStyled />
          </LoaderWrapperStyled>
        )}
        {currentPage < pagesCount && (
          <AnchorStyled onClick={handleFetchMore}>See more reviews</AnchorStyled>
        )}
      </SectionStyled>
    ) : null
  );
};

export default memo(Reviews);
