import { gql } from '@apollo/client';

import { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';

export const GET_BUSINESS_PROFILE = gql`
  query GetBusinessPublicProfile($businessSlug: String!) {
    getBusinessPublicProfile(businessSlug: $businessSlug) {
      response {
        code
        redirectUrl
      }
      business {
        id
        name
        noindex
        primaryCategory {
          id
          name
        }
        acceptedPaymentMethods {
          amex
          cash
          cheque
          directDeposit
          mastercard
          paypal
          visa
        }
        logoId
        logoFileName
        feedbackCount
        feedbackAvg
        abn
        featuredListing
        newToOneflare
        verified
        address
        website
        yearFounded
        suburb
        state
        socialMediaLinks {
          platform
          url
        }
        available247
        openPublicHolidays
        description
        openingHours {
          openStatus
          formattedOpenHours
          day
          closed
        }
        qualifications {
          name
          number
          type
        }
        badges {
          id
          name
        }
        awards {
          id
          name
          year
        }
        gallery {
          filename
          id
          name
          original
          thumb
        }
        faqs {
          answer
          id
          profileQuestion {
            question
          }
        }
        uniqueSellingPoints
        serviceTypes {
          id
          name
          primaryCategory {
            slug
          }
        }
        nearbyCategoryLocations {
          suburb
          url
        }
        breadcrumbsV2 {
          title
          url
        }
        facebookReviewsStats {
          reviewsAverage
          reviewsCount
        }
        googleReviewsStats {
          reviewsAverage
          reviewsCount
        }
        oneflareReviewsStats {
          reviewsAverage
          reviewsCount
        }
        facebookReviewsUrl
        googleReviewsUrl
        featuredTiles {
          category {
            verb
          }
          location {
            suburb
          }
        }
        hiredCount
        phone
        landline
        reviewedByCurrentUser
        servicingZoneNames
        directMessagingEligible
        showLandlineInDirectory
        showPhoneInDirectory
        postcode
      }
    }
  }
`;

export type GetBusinessPublicProfileResponse = {
  getBusinessPublicProfile: {
    response: {
      code: string;
      redirectUrl: string;
    };
    business: BusinessPublicProfileModel
  };
};


export const GET_FEEDBACKS = gql`
  query getFeedbacks($uuids: [String!]) {
    getFeedbacks(uuids: $uuids) {
      avatarPath
      byFirstNameLastInitial
      createdAt
      for {
        business {
          name
        }
      }
      id
      job {
        title
        location {
          state
          suburb
        }
      }
      notes
      origin
      score
      verified
    }
  }
`;

export const TRACK_BUSINESS = gql`
  mutation trackBusiness(
    $businessId: ID!
    $property: String!
    $trackingOptions: TrackingOptions
  ) {
    trackBusiness(
      businessId: $businessId
      property: $property
      trackingOptions: $trackingOptions
    ) {
      success
    }
  }
`;
