import { styled, css, ExecutionContext } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import H2 from '@oneflare/flarekit/lib/components/H2';

export const SectionStyled = styled.section<{
  $portfolio?: boolean;
}>`
  padding: 32px ${({ $portfolio }) => ($portfolio ? 0 : '16px')};
  border-top: 1px solid ${({ theme }) => theme.color.cloud};

  &:first-of-type {
    padding-top: 0;
    border-top: 0;
  }

  ${media.mdUp`
    padding: 32px ${({ $portfolio }: ExecutionContext & {
      $portfolio?: boolean;
    }) => ($portfolio ? '16px' : '32px')};
  `}

  ${media.xlUp`
    padding: 40px ${({ $portfolio }: ExecutionContext & {
      $portfolio?: boolean;
    }) => ($portfolio ? '24px' : '40px')};
  `}
`;

export const TitleStyled = styled(H2)<{
  $withMargin?: boolean;
  $centerTitle?: boolean;
}>`
  font-size: 16px;

  ${({ $centerTitle }) => $centerTitle && css`
    text-align: center;

    ${media.mdUp`
      text-align: left;
    `}
  `}

  ${({ $withMargin }) => $withMargin && css`
    margin: 0 16px 24px;
  `}
`;
