import { styled, css } from 'styled-components';
import Oneflare from '@oneflare/flarekit/lib/components/logos/2018/Oneflare';
import Facebook from '@oneflare/flarekit/lib/components/logos/Facebook';
import Google from '@oneflare/flarekit/lib/components/logos/Google';

const logoStyles = css`
  width: 14px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  left: 40px;
`;

const statLogoStyles = css`
  width: 14px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  padding: 0;
  border-radius: 50%;
  margin-right: 9px;
`;

const attrs = () => ({
  width: '14px'
});

type Logo = {
  $isAbsolute?: boolean;
}

export const OneflareStyled = styled(Oneflare).attrs(attrs)<Logo>`
  padding: 5px 21px 4px 4px;
  ${({ $isAbsolute }) => ($isAbsolute ? logoStyles : statLogoStyles)}
`;

export const FacebookStyled = styled(Facebook).attrs(attrs)<Logo>`
  padding: 3px 19px 3px 4px;
  ${({ $isAbsolute }) => ($isAbsolute ? logoStyles : statLogoStyles)}
`;

export const GoogleStyled = styled(Google).attrs(attrs)<Logo>`
  padding: 4px 20px 2px 4px;
  ${({ $isAbsolute }) => ($isAbsolute ? logoStyles : statLogoStyles)}
`;
