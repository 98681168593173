import {
  FC,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CategoryLocationUrl } from 'types/oneflare.com.au/businessPublicProfile';
import isEmpty from 'lodash/isEmpty';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import pluralize from 'pluralize';
import { isMobile } from 'react-device-detect';
import { useBreakpoint } from 'shared/utils/hooks';
import { TitleStyled } from '../../styled/BppHeader';
import { SectionStyled, LocationsListStyled, ButtonLinkStyled } from './styled/NearbyLocations';

type Props = {
  category: string;
  nearbyCategoryLocations: Array<CategoryLocationUrl>;
};

const NearbyLocations: FC<Props> = ({ category, nearbyCategoryLocations }: Props) => {
  // suburb is stripped from url to get the suburb directory link,
  // eg. from /rubbish-removal/nsw/[suburb] to /rubbish-removal/nsw
  const suburbDirectoryLink = useMemo(() => (
    nearbyCategoryLocations[0]?.url.replace(/(\/[^/]+)$/g, '')
  ), [nearbyCategoryLocations]);

  const [categoryTitle, setCategoryTitle] = useState(() => {
    // initialilize title in order to sync hydration
    // and prevent html mismatch
    return isMobile
      ? pluralize(category.toLowerCase())
      : `${category.toLowerCase()} experts`;
  });

  const isLgUp = useBreakpoint('lg');
  useEffect(() => {
    // update title based on window rescaling
    const categoryInTitle = isLgUp
      ? `${category.toLowerCase()} experts`
      : pluralize(category.toLowerCase());
    setCategoryTitle(categoryInTitle);
  }, [category, isLgUp]);

  return isEmpty(nearbyCategoryLocations) ? null : (
    <SectionStyled>
      <TitleStyled $centerTitle>{`Browse ${categoryTitle} by suburb`}</TitleStyled>
      <LocationsListStyled>
        {nearbyCategoryLocations.map(({ suburb, url }) => (
          <li key={suburb}>
            <Anchor href={url}>
              {suburb}
            </Anchor>
          </li>
        ))}
      </LocationsListStyled>
      <ButtonLinkStyled
        kind="outline-dark-sm"
        link={suburbDirectoryLink}
      >
        View suburb directory
      </ButtonLinkStyled>
    </SectionStyled>
  );
};

export default memo(NearbyLocations);
