import type { FunctionComponent } from 'react';
import type { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import type { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';
import {
  Fragment,
  memo,
  useMemo,
  useState
} from 'react';
import { capitalizeFirstLetter, formatPhoneNumber } from 'shared/utils/helpers';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import {
  parseNumber, isValidNumber, ParsedNumber
} from 'libphonenumber-js';
import { ReactTooltipStyled } from '../../../../styled/BusinessPublicProfile';
import {
  TitleStyled,
  SectionWrapperStyled,
  RowWrapperStyled,
  DetailCopyStyled,
  DetailLinkStyled
} from '../../styled/BppHeader';

type Props = {
  business: BusinessPublicProfileModel;
  tracker: BusinessProfileTrackerModel;
};

const ContactDetails: FunctionComponent<Props> = ({ business, tracker }) => {
  const [phonesExpanded, setPhonesExpanded] = useState({
    mobile: false,
    landline: false
  });

  const phones = useMemo(() => [
    {
      type: 'mobile',
      number: business.phone,
      showInDirectory: business.showPhoneInDirectory
    },
    {
      type: 'landline',
      number: business.landline,
      showInDirectory: business.showLandlineInDirectory
    }
  ], [business]);

  const filteredPhones = useMemo(() => phones
    .filter(({ number, showInDirectory }) => number && showInDirectory), [phones]);

  const handlePhoneClick = (e: MouseEvent, type: string) => {
    tracker.interaction('phone', { action: phonesExpanded[type] ? 'call_text' : 'click_text' });
    if (!phonesExpanded[type]) {
      e.preventDefault();
      setPhonesExpanded((prev) => ({
        ...prev,
        [type]: true
      }));
    }
  };

  return (
    <SectionWrapperStyled>
      <TitleStyled>
        Business contact details
      </TitleStyled>
      {filteredPhones.map(({ type, number }) => (
        <Fragment key={type}>
          <RowWrapperStyled>
            <DetailLinkStyled>
              <strong>
                {`${capitalizeFirstLetter(type)}:`}
              </strong>
              {isValidNumber(parseNumber(`${number}`, 'AU') as ParsedNumber) ? (
                <Anchor
                  onClick={(e) => handlePhoneClick(e, type)}
                  data-tooltip-id={`${type}-tooltip`}
                  data-tooltip-content="Click to show number"
                  href={phonesExpanded[type] ? formatPhoneNumber(number).phoneUrl : '#'}
                >
                  {formatPhoneNumber(number, { truncate: !phonesExpanded[type] }).phoneFormatted}
                </Anchor>
              ) : 'NA'}
            </DetailLinkStyled>
          </RowWrapperStyled>
          {!phonesExpanded[type] && (
            <ReactTooltipStyled id={`${type}-tooltip`} />
          )}
        </Fragment>
      ))}
      <RowWrapperStyled>
        <DetailCopyStyled>
          <strong>
            Address:
          </strong>
          {`${business.address ? `${business.address}, ` : ''}${business.suburb}, ${business.state}`}
        </DetailCopyStyled>
      </RowWrapperStyled>
      {business.website && (
        <RowWrapperStyled>
          <DetailLinkStyled>
            <strong>
              Website:
            </strong>
            <Anchor
              href={business.website}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => tracker.interaction('website')}
            >
              {business.website}
            </Anchor>
          </DetailLinkStyled>
        </RowWrapperStyled>
      )}
      {Boolean(business.yearFounded) && (
        <RowWrapperStyled>
          <DetailCopyStyled>
            <strong>
              Year founded:
            </strong>
            {business.yearFounded}
          </DetailCopyStyled>
        </RowWrapperStyled>
      )}
    </SectionWrapperStyled>
  );
};

export default memo(ContactDetails);
