import { styled, css } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import ImageUpload from '@oneflare/flarekit/lib/components/icons/ImageUpload';
import CloudUploader from '@oneflare/flarekit/lib/components/icons/CloudUploader';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

export const DropContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.aluminium};
  border: 1px dashed transparent;
  background: ${({ theme }) => {
    const { color: { white } } = theme;
    return `linear-gradient(rgb(243, 251, 255), rgb(243, 251, 255)) padding-box, repeating-linear-gradient(-45deg, rgb(0, 145, 234) 0, rgb(0, 145, 234) 5px, ${white} 0, ${white} 10px);`;
  }};
  outline: none;
  transition: border .24s ease-in-out;
`;

export const BrowseStyled = styled(Anchor)`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.infoDark};
  cursor: pointer;
`;

export const ImageUploadStyled = styled(ImageUpload).attrs(({ theme }) => ({
  kind: 'hollow',
  fill: theme.color.spaceGrey
}))`
  height: 24px;
  width: 24px;
`;

export const CloudUploaderStyled = styled(CloudUploader).attrs(({ theme }) => ({
  kind: 'hollow',
  fill: theme.color.spaceGrey
}))`
  height: 24px;
  width: 24px;
`;

export const ParagraphStyled = styled(Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.color.gunMetal};
  margin-bottom: 16px;

  ${media.lgUp`
    margin-bottom: 14px;
  `}

  &:nth-of-type(1) {
    margin: 8px 0;
  }

  &:nth-of-type(2) {
    font-size: 14px;
    margin: 0;
  }
`;

export const PlaceholderStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => theme.color.spaceGrey};
`;

export const WrapperStyled = styled.span`
  outline: none;
`;

export const VerificationOverrideValues = css`
  padding: 6px;
  margin-left: 8px;
  justify-content: center;
  flex-basis: 50%;

  span {
    width: 16px;
  }

  p.Paragraph {
    margin: 0;
    max-width: 140px;
  }

  ${media.smUp`
    margin-left: 16px;
    width: 196px;

    p.Paragraph {
      margin: 8px 0 0;
    }

    span {
      width: 24px;
    }
  `}
`;
