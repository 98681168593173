import { styled } from 'styled-components';
import H3 from '@oneflare/flarekit/lib/components/H3';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

export const FaqListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 32px 0 0;
`;

export const FaqItemStyled = styled.li`
  padding: 40px 0;
  border-top: 1px solid ${({ theme }) => theme.color.cloud};

  &:first-of-type {
    padding-top: 0;
    border-top: none;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const H3Styled = styled(H3)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.gunMetal};
  margin-bottom: 16px;
`;

export const ParagraphStyled = styled(Paragraph)`
  word-break: break-word;
`;

export const AnchorStyled = styled(Anchor)`
  display: block;
  margin-top: 8px;
`;
