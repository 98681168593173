import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import {
  memo,
  useMemo,
  type MutableRefObject
} from 'react';

import { ReviewStats } from 'types/oneflare.com.au/businessPublicProfile';

import { SectionWrapperStyled, TitleStyled } from '../../styled/BppHeader';
import { scrollToReviews } from '../../utils';

import {
  RatingWrapperStyled,
  RatingAvgStyled,
  StarRatingStyled,
  ParagraphStyled,
  OriginsListStyled,
  OriginItemStyled,
  OriginDetailsStyled,
  OneflareStyled,
  FacebookStyled,
  GoogleStyled,
  SubRatingAvgStyled,
  StarStyled,
  TruncateTextStyled
} from './styled/RatingSummary';

type RatingSummaryProps = {
  feedbackAvg: number;
  facebookReviewsStats: ReviewStats;
  googleReviewsStats: ReviewStats;
  oneflareReviewsStats: ReviewStats;
  facebookReviewsUrl: string;
  googleReviewsUrl: string;
  isStickyHeader: boolean;
  reviewsSectionRef: MutableRefObject<HTMLElement | null>;
};

const RatingSummary = ({
  feedbackAvg,
  facebookReviewsStats,
  googleReviewsStats,
  oneflareReviewsStats,
  facebookReviewsUrl,
  googleReviewsUrl,
  isStickyHeader,
  reviewsSectionRef
}: RatingSummaryProps) => {
  const reviewsStatsArr = useMemo(() => ([
    {
      id: 'oneflareReviewsStats',
      stats: oneflareReviewsStats,
      logo: <OneflareStyled />,
      link: '#',
      isExternal: false
    },
    {
      id: 'googleReviewsStats',
      stats: googleReviewsStats,
      logo: <GoogleStyled />,
      link: googleReviewsUrl,
      isExternal: true
    },
    {
      id: 'facebookReviewsStats',
      stats: facebookReviewsStats,
      logo: <FacebookStyled />,
      link: facebookReviewsUrl,
      isExternal: true
    }
  ]), [
    oneflareReviewsStats,
    googleReviewsStats,
    facebookReviewsStats,
    googleReviewsUrl,
    facebookReviewsUrl
  ]);

  const filteredReviewsStatsArr = useMemo(() => (
    reviewsStatsArr.filter((el) => el.stats?.reviewsCount)
  ), [reviewsStatsArr]);

  const externalReviewStatsArr = useMemo(() => (
    filteredReviewsStatsArr.filter((el) => el.isExternal)
  ), [filteredReviewsStatsArr]);

  const oneflareFound = useMemo(() => (
    filteredReviewsStatsArr.find((el) => el.id === 'oneflareReviewsStats')
  ), [filteredReviewsStatsArr]);

  const handleClick = (e: MouseEvent) => {
    scrollToReviews(e, reviewsSectionRef, isStickyHeader);
  };

  return !isEmpty(externalReviewStatsArr) ? (
    <SectionWrapperStyled>
      <TitleStyled>Rating summary</TitleStyled>
      {feedbackAvg && (
        <RatingWrapperStyled>
          <RatingAvgStyled>{feedbackAvg.toFixed(1)}</RatingAvgStyled>
          <StarRatingStyled rating={feedbackAvg} />
        </RatingWrapperStyled>
      )}

      {oneflareFound && (
        <ParagraphStyled>
          {`Total average rating based on reviews from Oneflare and
          ${externalReviewStatsArr.length} other
          ${pluralize('platform', (externalReviewStatsArr.length))}`}
        </ParagraphStyled>
      )}

      <OriginsListStyled>
        {filteredReviewsStatsArr.map(({
          id,
          stats,
          logo,
          link,
          isExternal
        }) => (
          <OriginItemStyled key={id}>
            <OriginDetailsStyled>
              {logo}
              <SubRatingAvgStyled>{stats?.reviewsAverage.toFixed(1)}</SubRatingAvgStyled>
              <StarStyled />
            </OriginDetailsStyled>
            <Anchor
              href={link}
              target={isExternal ? '_blank' : undefined}
              rel={isExternal ? 'noopener noreferrer' : undefined}
              onClick={isExternal ? undefined : handleClick}
            >
              <TruncateTextStyled
                lines={1}
                text={`${stats?.reviewsCount}+ ${id === 'facebookReviewsStats' ? 'recommendations' : 'reviews'}`}
              />
            </Anchor>
          </OriginItemStyled>
        ))}
      </OriginsListStyled>
    </SectionWrapperStyled>
  ) : null;
};

export default memo(RatingSummary);
