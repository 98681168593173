import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ItemStyled,
  FileNameStyled,
  ReasonStyled
} from '../../styled/ErrorFileList';

const ErrorItem = ({ fileName, error, showMobileOnly }) => (
  <ItemStyled showMobileOnly={showMobileOnly}>
    <FileNameStyled showMobileOnly={showMobileOnly}>{fileName}</FileNameStyled>
    <ReasonStyled showMobileOnly={showMobileOnly}>{error}</ReasonStyled>
  </ItemStyled>
);

const propTypes = {
  error: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  showMobileOnly: PropTypes.bool.isRequired
};

ErrorItem.propTypes = propTypes;

export default memo(ErrorItem);
