import { FC, memo } from 'react';
import FreeQuotesForm from '../../../../../shared/components/FreeQuotesForm';
import { GetFreeQuotesWrapperStyled } from './styled/BppFooter';

type Props = {
  isStickyHeader: boolean;
};

const BppFooter: FC<Props> = ({ isStickyHeader }: Props) => {
  return (
    <GetFreeQuotesWrapperStyled $isStickyHeader={isStickyHeader}>
      <FreeQuotesForm kind="narrow" />
    </GetFreeQuotesWrapperStyled>
  );
};

export default memo(BppFooter);
