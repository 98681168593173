import { FC, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import { SectionStyled, TitleStyled } from '../../styled/BppBody';
import {
  ListStyled,
  ListItemStyled,
  RightArrowStyled
} from './styled/UniqueSellingPoints';

type Props = {
  uniqueSellingPoints: Array<string>;
};

const UiqueSellingPoints: FC<Props> = ({ uniqueSellingPoints }: Props) => {
  const filteredUniqueSellingPoints = uniqueSellingPoints?.filter(
    (uniqueSellingPoint) => uniqueSellingPoint
  );

  return (
    isEmpty(filteredUniqueSellingPoints) ? null : (
      <SectionStyled>
        <TitleStyled>Unique selling points</TitleStyled>
        <ListStyled>
          {filteredUniqueSellingPoints.map((uniqueSellingPoint) => (
            <ListItemStyled key={uniqueSellingPoint}>
              <RightArrowStyled />
              <Paragraph>{uniqueSellingPoint}</Paragraph>
            </ListItemStyled>
          ))}
        </ListStyled>
      </SectionStyled>
    )
  );
};

export default memo(UiqueSellingPoints);
