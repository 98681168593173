import { styled, css } from 'styled-components';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import ToggleContent from '@oneflare/flarekit/lib/components/ToggleContent';
import ABN from '@oneflare/flarekit/lib/components/icons/ABN';
import Documents from '@oneflare/flarekit/lib/components/icons/Documents';
import Insurance from '@oneflare/flarekit/lib/components/icons/Insurance';
import Certification from '@oneflare/flarekit/lib/components/icons/Certification';
import Success from '@oneflare/flarekit/lib/components/icons/Success';

export const ParagraphStyled = styled(Paragraph)`
  margin-top: 16px;
`;

export const ToggleContentStyled = styled(ToggleContent)`
  margin-top: 16px;
`;

export const QualificationsListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const QualificationItemStyled = styled.li`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const QualificationDetailsStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const QualificationStatusStyled = styled(QualificationDetailsStyled)`
  margin-left: 8px;
`;

const iconAttrs = {
  kind: 'solid',
  width: '18px',
  height: '18px'
};

const iconStyles = css`
  margin-right: 8px;
  position: relative;
  top: 2px;
`;

export const ABNStyled = styled(ABN).attrs(({ theme }) => ({
  fill: theme.color.spaceGrey,
  ...iconAttrs
}))`
  ${iconStyles}
`;

export const DocumentsStyled = styled(Documents).attrs(({ theme }) => ({
  fill: theme.color.spaceGrey,
  ...iconAttrs
}))`
  ${iconStyles}
`;

export const InsuranceStyled = styled(Insurance).attrs(({ theme }) => ({
  fill: theme.color.spaceGrey,
  ...iconAttrs
}))`
  ${iconStyles}
`;

export const CertificationStyled = styled(Certification).attrs(({ theme }) => ({
  fill: theme.color.spaceGrey,
  ...iconAttrs
}))`
  ${iconStyles}
`;

export const SuccessStyled = styled(Success).attrs(({ theme }) => ({
  fill: theme.color.success,
  ...iconAttrs
}))`
  ${iconStyles}
`;
