import { FC, memo } from 'react';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import ModalNew from '@oneflare/flarekit/lib/components/ModalNew';
import { GET_FEEDBACKS } from 'queries/oneflare.com.au/businessPublicProfile';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { GetFeedbacksResponseModel, GetFeedbacksRequestModel } from 'types/oneflare.com.au/businessPublicProfile';
import Review from '../BppBody/components/Reviews/components/Review';

type Props = {
  feedbackId: string;
  handleClose: () => void;
  show: boolean;
};

const ReviewModal: FC<Props> = ({ feedbackId, handleClose, show }) => {
  const { data, loading } = useQuery<GetFeedbacksResponseModel, GetFeedbacksRequestModel>(
    GET_FEEDBACKS,
    {
      variables: { uuids: [feedbackId] },
      onError: (err) => DataDogRumAgent.addRumError(err, 'Oneflare | Business Public Profile | GET_FEEDBACKS query')
    }
  );

  if (loading || isEmpty(data?.getFeedbacks)) return null;

  const feedbackData = data?.getFeedbacks[0];

  return (
    <ModalNew
      show={show}
      onClose={handleClose}
      title={`${feedbackData?.byFirstNameLastInitial} left a review for ${feedbackData?.for?.business?.name}`}
    >
      <Review
        avatarUrl={feedbackData?.avatarPath}
        businessName={feedbackData?.for?.business?.name}
        id={Number(feedbackData?.id)}
        isReviewModal
        jobTitle={feedbackData?.job?.title}
        location={`${feedbackData?.job?.location?.suburb}, ${feedbackData?.job?.location?.state}`}
        origin={feedbackData.origin}
        postedAt={feedbackData?.createdAt}
        rating={feedbackData?.score}
        reviewerName={feedbackData?.byFirstNameLastInitial}
        reviewText={feedbackData?.notes}
        verified={feedbackData.verified}
      />
    </ModalNew>
  );
};

export default memo(ReviewModal);
