import { gql } from '@apollo/client';

export const GET_REVIEWS = gql`
  query getReviews($businessId: Int!, $page: Int, $perPage: Int) {
    getReviews(businessId: $businessId, page: $page, perPage: $perPage) {
      businessId
      pagesCount
      currentPage
      reviewCount
      reviews {
        id
        avatarUrl
        uuid
        origin
        reviewerName
        reviewText
        rating
        postedAt
        verified
        jobTitle
        location
        comment {
          message
          postedAt
        }
      }
    }
  }
`;
