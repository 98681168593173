import { gql } from '@apollo/client';

export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($jobId: ID!, $status: String!, $providerId: Int, $reason: String) {
    updateJobStatus(
      jobId: $jobId,
      status: $status,
      providerId: $providerId,
      reason: $reason
    ) {
      success
      message
    }
  }
`;

export const CREATE_FEEDBACK = gql`
  mutation createFeedback($businessId: Int!, $feedbackParams: CreateFeedback!, $jobId: Int, $userParams: RegisterAttributes) {
    createFeedback(
      businessId: $businessId,
      feedbackParams: $feedbackParams,
      jobId: $jobId,
      userParams: $userParams
    ) {
      id
      score
      notes
      pricePaid
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation updateFeedback($feedbackId: Int!, $feedbackParams: UpdateFeedback!) {
    updateFeedback(
      feedbackId: $feedbackId,
      feedbackParams: $feedbackParams
    ) {
      id
      score
      notes
      pricePaid
      updatedAt
      createdAt
    }
  }
`;
