const HELP_CENTRE_URL = 'https://help.oneflare.com.au/hc/en-us';
const BUSINESS_VERIFICATION_HELP_URL = `${HELP_CENTRE_URL}/sections/360000909275-Business-Verification`;
const VERIFIED_BUSINESS_HELP_URL = `${HELP_CENTRE_URL}/articles/13080250683289-What-does-it-mean-to-be-a-verified-business-on-Oneflare-`;
const ONTIME_BADGE_HELP_URL = `${HELP_CENTRE_URL}/articles/12995359326105-What-s-an-On-Time-Badge-and-how-do-I-get-one-`;
const REVIEW_MEET_GUIDELINES_HELP_URL = `${HELP_CENTRE_URL}/articles/12709729628825-Does-my-review-meet-the-guidelines`;
const I_AM_A_CUSTOMER_HELP_URL = `${HELP_CENTRE_URL}/categories/11608010763417-I-am-a-customer`;
const BUSINESS_WEB_REGISTER_HELP_URL = `${HELP_CENTRE_URL}/articles/12711774602393-How-do-I-register-my-business-using-the-web-browser-`;
const HOW_DO_I_GET_STARTED_HELP_URL = `${HELP_CENTRE_URL}/articles/12734059141017-I-am-ready-to-complete-my-profile-How-do-I-get-started-`;
const PLANS_HELP_URL = `${HELP_CENTRE_URL}/sections/12711322255385-Plans`;
const QUOTING_ON_JOBS_HELP_URL = `${HELP_CENTRE_URL}/sections/12711263319193-Quotes`;
const REVIEWS_HELP_URL = `${HELP_CENTRE_URL}/sections/11608072013337-Reviews`;
const MANAGING_MY_ACCOUNT_HELP_URL = `${HELP_CENTRE_URL}/sections/11608107905817-Manage-Account`;
const REVIEW_GUIDELINES_HELP_URL = `${HELP_CENTRE_URL}/articles/217082748-Review-guidelines`;

export {
  REVIEW_MEET_GUIDELINES_HELP_URL,
  HELP_CENTRE_URL,
  PLANS_HELP_URL,
  REVIEWS_HELP_URL,
  QUOTING_ON_JOBS_HELP_URL,
  MANAGING_MY_ACCOUNT_HELP_URL,
  HOW_DO_I_GET_STARTED_HELP_URL,
  BUSINESS_WEB_REGISTER_HELP_URL,
  I_AM_A_CUSTOMER_HELP_URL,
  BUSINESS_VERIFICATION_HELP_URL,
  VERIFIED_BUSINESS_HELP_URL,
  REVIEW_GUIDELINES_HELP_URL,
  ONTIME_BADGE_HELP_URL
};
