import { styled, css } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import Documents from '@oneflare/flarekit/lib/components/icons/Documents';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Tag from '@oneflare/flarekit/lib/components/Tag';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

const isUploading = (percentage, notLoading, loading) => (
  percentage === 100 ? notLoading : loading
);

export const DocItemWrapperStyled = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.tin};
  margin-top: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 16px ${({ percentage }) => isUploading(percentage, '88px', '16px')} 16px 46px;

  ${media.lgUp`
    padding: 16px ${({ percentage }) => isUploading(percentage, '88px', '16px')} 16px 38px;
  `}

  &:not(:first-child) {
    margin-top: ${({ hasSingleFileLimit }) => (hasSingleFileLimit ? 0 : '16px')};
  }
`;

export const FileWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const FileStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 0 98px 0 0;
  box-sizing: border-box;
  align-items: center;
  position: relative;
`;

export const IconStyled = styled(Documents).attrs(({ theme }) => ({
  fill: theme.color.silver,
  width: '100%',
  height: '100%',
  kind: 'hollow'
}))`
  position: absolute;
  margin: 0 auto;
  left: 16px;
  width: 24px;
  height: 24px;

  ${media.lgUp`
    width: 16px;
    height: 16px;
  `}
`;

export const FileNameStyled = styled(Paragraph)`
  margin: 0 8px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
`;

export const StatusTagStyled = styled(Tag)`
  flex: 0;
`;

const removeStyles = css`
  position: absolute;
  margin: 0 auto;
  right: 16px;
`;

export const RemoveStyled = styled(Anchor)`
  ${removeStyles}
`;

export const RemoveDisabledStyled = styled(Paragraph)`
  ${removeStyles}
`;
