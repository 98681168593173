const MAX_RATING = 5;

// Returns the rating as an array of values from 0 - 4 indicating
// how many quarters of each star need to be colored in
export const starQuarters = (rating = 0) => {
  return new Array(MAX_RATING)
    .fill(undefined)
    .map((_, index) => {
      const base = Math.ceil((rating - index) / 0.25);
      return Math.max(0, Math.min(4, base));
    });
};
