import { styled, css } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import H4 from '@oneflare/flarekit/lib/components/H4';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import UploadFiles from 'shared/components/UploadFiles';

export const UploadDocsWrapperStyled = styled.section`
  ${({ kind, theme }) => kind === 'credentials' && css`
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.cloud};
    }
  `}
`;

export const DescriptionWrapperStyled = styled.div`
  ${({ kind }) => kind === 'credentials' && css`
    padding: 40px 0 0;

    ${media.lgUp`
      padding-bottom: 8px;
    `}
  `}
`;

export const UploaderWrapperStyled = styled.section`
  padding: 0 0 40px;
`;

export const UploadFilesStyled = styled(UploadFiles)`
  display: ${({ length, hasSingleFileLimit }) => ((hasSingleFileLimit && length) ? 'none' : 'flex')};
`;

export const TitleStyled = styled(H4)`
  margin-bottom: 8px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 16px;
  color: ${({ theme }) => theme.color.charcoal};
`;

export const TipsStyled = styled(Paragraph)`
  width: 100%;
  margin: 0 auto ${({ kind }) => (kind === 'invoice' ? '8px' : '24px')};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.gunMetal};
`;
