import {
  ABNStyled,
  DocumentsStyled,
  InsuranceStyled,
  CertificationStyled
} from '../styled/Qualifications';

export const renderQualificationIcon = (type: string) => {
  switch (type) {
    case 'abn':
      return <ABNStyled />;
    case 'licence':
      return <DocumentsStyled />;
    case 'insurance':
      return <InsuranceStyled />;
    case 'certificate':
      return <CertificationStyled />;
    default:
      return null;
  }
};
