import { styled } from 'styled-components';
import media from '@oneflare/flarekit/lib/libs/media';
import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';

export const SectionStyled = styled.section`
  padding: 35px 20px 0;
  text-align: center;

  ${media.lgUp`
    padding: 0 0 32px;
    text-align: left;
  `}
`;

export const LocationsListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 24px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export const ButtonLinkStyled = styled(ButtonLink)`
  margin-top: 16px;
  width: 100%;
`;
