import { styled } from 'styled-components';
import Anchor from '@oneflare/flarekit/lib/components/Anchor';

export const ServicesListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const ServiceItemStyled = styled.li`
  margin: 8px 8px 0 0;
`;

export const AnchorStyled = styled(Anchor)`
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 13px;

  &:hover {
    color: ${({ theme }) => theme.color.gunMetal};
  }
`;
